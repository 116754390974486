/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import { Box, Card, CardContent, CardHeader, CircularProgress, Divider, List, ListItemButton, ListItemText } from '@mui/material';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import SearchIcon from '../../../components/svgComponents/searchIcon';
import { InputStyle } from '../toolbar/profile-list-toolbar.styles';
import { UserListItem } from './user-list.types';
// ______________________________________________________

interface ProfileUsersListProps {
  filterKeyword: string;
  onFilterKeyword: (keyword: string) => void;
  onSelectUser: (id: number) => void;
  users?: UserListItem[];
  selectedUserId?: number;
  isFetchingUsers?: boolean;
}
export default function UsersList({ users, filterKeyword, onFilterKeyword, onSelectUser, selectedUserId, isFetchingUsers }: ProfileUsersListProps) {
  const { t } = useTranslation();
  const hasUsers = !isFetchingUsers && users && users.length > 0;
  return (
    <Card sx={{ minHeight: '62vh' }}>
      <CardHeader title={'Liste des utilisateurs par mail'} />
      <Divider />
      <CardContent>
        <InputStyle
          value={filterKeyword}
          placeholder={t('common.search')}
          onChange={(e: ChangeEvent<HTMLInputElement>) => onFilterKeyword(e.target.value)}
          InputProps={{
            startAdornment: <SearchIcon />,
          }}
          sx={{
            '.MuiOutlinedInput-root': {
              width: '100%',
            },
          }}
        />
        <List className="artgapi-user-email-wrapper" component="nav" aria-label="secondary mailbox folder">
          {hasUsers ? (
            users.map((user) => (
              <ListItemButton
                className="artgapi-user-email-lists"
                key={user.id}
                selected={selectedUserId === user.id}
                onClick={(event) => {
                  onSelectUser(user.id);
                }}
                sx={{
                  paddingLeft: '8px',
                  paddingRight: '8px',
                }}
              >
                <ListItemText primary={user.mail} />
              </ListItemButton>
            ))
          ) : (
            <h1>{t('adds.user_no_data')}</h1>
          )}
        </List>
        {isFetchingUsers && (
          <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} padding={1} paddingY={'15%'}>
            <CircularProgress
              sx={{
                color: '#ffcc33',
              }}
            />
          </Box>
        )}
      </CardContent>
    </Card>
  );
}
