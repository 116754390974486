/* eslint-disable @typescript-eslint/no-explicit-any*/
import React, { ChangeEvent, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import {
  Backdrop,
  Box,
  ButtonBase,
  CircularProgress,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { theme } from '../../../theme/theme';
import { RootState } from '../../../store/rootStore';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { fr } from 'date-fns/locale';
import DateIcon from '../../svgComponents/DateIcon';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Company from '../../../models/Company';
import { clearErrors, useCreateCompany } from '../../../store/campagnes/actions';
import { compagniesActionTypes } from '../../../store/campagnes/models';
import CloseIcon from '../../svgComponents/closeIcon';
import { Stack } from '@mui/material';
import CheckIcon from '../../svgComponents/CheckIcon';
import { checkMediaSize } from '../../../utils';
import { colors } from '../../../theme/sharedTheme';

interface CreerCampagneDialogProps {
  openCreerCampagneDialog: boolean;
  setOpenCreerCampagneDialog: any;
}
export const CreerCampagneDialog = (props: CreerCampagneDialogProps) => {
  const [selectedFile, setSelectedFile] = useState<File>();
  const [fileName, setFileName] = useState<string>('');
  const compagniesState = useSelector((state: RootState) => state.compagnies.newCompany);
  const { loader } = compagniesState;
  const { openCreerCampagneDialog, setOpenCreerCampagneDialog } = props;
  const { createCompany } = useCreateCompany();
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, compagniesActionTypes>>();

  const handleClose = () => {
    setOpenCreerCampagneDialog(false);
    formik.resetForm();
    setFileName('');
    setSelectedFile(undefined);
    dispatch(clearErrors());
  };
  const onCreateCompany = (data: Company, file: File) => {
    dispatch(createCompany(data, file));
  };
  const schema = yup.object().shape({
    title: yup
      .string()
      .matches(/^[^-\s][a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ0-9_\s-]+$/, 'Le titre est invalide')
      .max(32)
      .required('Le titre est requis'),
    image: yup.string().required('Média est requis'),
    startDate: yup
      .date()
      .max(yup.ref('endDate'), 'La date de début doit etre inferieur à la date de fin')
      .nullable()
      .required('La date de début est obligatoire')
      .nullable(),
    endDate: yup.date().nullable().required('La date de fin est obligatoire'),
    url: yup
      .string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "L'url n'est pas valide",
      )
      .required("L'url est obligatoire"),
  });
  const formik = useFormik({
    validationSchema: schema,
    enableReinitialize: true,
    initialValues: {
      title: '',
      type: '0',
      platform: 'ios',
      format: 'image',
      image: '',
      video: '',
      startDate: '',
      endDate: '',
      url: '',
      status: '',
    },
    onSubmit: (values, { resetForm }) => {
      if (selectedFile) {
        onCreateCompany(values, selectedFile);
      }
      if (loader) resetForm();
    },
  });

  useEffect(() => {
    if (!loader && compagniesState.error === '') handleClose();
  }, [loader, compagniesState.error]);
  const fileHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files instanceof FileList) {
      const file = event.target.files[0];
      setFileName(file.name);
      setSelectedFile(file);
      formik.setFieldValue('image', file.type.split('/')[0]);
    }
  };
  return (
    <Dialog
      open={openCreerCampagneDialog}
      onClose={handleClose}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            maxHeight: 'calc(100vh - 40px)',
            width: '500px',
            maxWidth: '500px',
            borderRadius: 0,
          },
        },
      }}
    >
      <Backdrop
        sx={{
          color: colors.yellow,
          display: 'flex',
          flexDirection: 'column',
          gap: '8',
          zIndex: '1000',
        }}
        open={loader}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
        <h2>téléchargement en cours</h2>
      </Backdrop>
      <Box
        sx={{
          width: '500px',
          color: theme.palette.primary.main,
          fontFamily: 'SFPro-Regular',
          backgroundColor: theme.palette.secondary.main,
          textAlign: 'center',
        }}
      >
        <IconButton sx={{ position: 'absolute', right: 5, top: 5 }} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          pt: '40px',
          fontSize: '24px',
          color: theme.palette.primary.main,
          fontFamily: 'SFPro-Regular',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: theme.palette.secondary.main,
          textAlign: 'center',
        }}
      >
        NOUVELLE CAMPAGNE
      </Box>

      <DialogContent sx={{ backgroundColor: theme.palette.secondary.main }}>
        <form onSubmit={formik.handleSubmit} id="createForm">
          <Grid item>
            <Grid item sx={{ display: 'flex' }}>
              <InputLabel htmlFor="title">Titre</InputLabel>
            </Grid>
            <InputBase
              id="title"
              sx={{ width: '100%' }}
              placeholder="Titre de la campagne"
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={!!formik.errors.title && formik.touched.title}
            />
            {formik.touched.title && formik.errors.title && (
              <Typography
                variant="caption"
                sx={{
                  color: '#ff453a',
                  marginBottom: '7px',
                  width: '170px',
                  height: '29px',
                  fontFamily: 'SFPro-Regular',
                  fontSize: '12px',
                  textAlign: 'left',
                }}
                data-testid="title"
              >
                {formik.errors.title}
              </Typography>
            )}
          </Grid>
          <Grid item sx={{ marginTop: '10px ' }}>
            <Grid item sx={{ display: 'flex' }}>
              <InputLabel htmlFor="type">Type</InputLabel>
            </Grid>
            <Grid item sx={{ display: 'flex' }}>
              <ToggleButtonGroup
                fullWidth
                exclusive
                value={formik.values.type}
                onChange={(e: React.ChangeEvent<any>) => {
                  formik.handleChange(e);
                }}
              >
                <ToggleButton id="type" sx={{ textTransform: 'none' }} disabled={formik.values.type === '0' ? true : false} value="0">
                  Interstitiel
                </ToggleButton>
                <ToggleButton id="type" sx={{ textTransform: 'none' }} disabled={formik.values.type === '1' ? true : false} value="1">
                  Post sponsorisé
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
          <Grid item sx={{ marginTop: '10px ' }}>
            <Grid item sx={{ display: 'flex' }}>
              <InputLabel htmlFor="plateform">Plateform</InputLabel>
            </Grid>
            <Grid item sx={{ display: 'flex' }}>
              <ToggleButtonGroup
                color="standard"
                fullWidth
                exclusive
                value={formik.values.platform}
                onChange={(e: React.ChangeEvent<any>) => {
                  formik.handleChange(e);
                }}
              >
                <ToggleButton id="platform" sx={{ textTransform: 'none' }} disabled={formik.values.platform === 'ios' ? true : false} value="ios">
                  iOS
                </ToggleButton>
                <ToggleButton
                  id="platform"
                  sx={{ textTransform: 'none' }}
                  disabled={formik.values.platform === 'android' ? true : false}
                  value="android"
                >
                  Android
                </ToggleButton>
                <ToggleButton id="platform" sx={{ textTransform: 'none' }} disabled={formik.values.platform === 'all' ? true : false} value="all">
                  iOS &amp; Android
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>

          <Grid item sx={{ marginTop: '10px ' }}>
            <Grid item sx={{ display: 'flex' }}>
              <InputLabel htmlFor="format">Format</InputLabel>
            </Grid>
            <ToggleButtonGroup
              onClick={() => {
                setFileName('');
                setSelectedFile(undefined);
              }}
              fullWidth
              exclusive
              value={formik.values.format}
              onChange={(e: React.ChangeEvent<any>) => {
                formik.handleChange(e);
              }}
            >
              <ToggleButton id="format" sx={{ textTransform: 'none' }} disabled={formik.values.format === 'image' ? true : false} value="image">
                Photo
              </ToggleButton>
              <ToggleButton id="format" sx={{ textTransform: 'none' }} disabled={formik.values.format === 'video' ? true : false} value="video">
                Vidéo
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item sx={{ marginTop: '10px ' }}>
            <input
              style={{ display: 'none' }}
              id="contained-button-file"
              type="file"
              accept={formik.values.format === 'image' ? 'image/png,image/jpeg' : 'video/mp4,video/x-m4v,video/*'}
              onChange={(event) => {
                fileHandler(event);
              }}
              onBlur={() => formik.setFieldTouched('image')}
            />
            <label htmlFor="contained-button-file">
              <ButtonBase
                component="span"
                sx={{
                  width: '100%',
                  height: '46px',
                  fontFamily: 'SFPro-Regular',
                  fontSize: '17px',
                  backgroundColor: '#ffffff',
                  color: theme.palette.primary.main,
                  textAlign: 'center',
                  justifySelf: 'end',
                  border: 'solid 1px #e7e7e7',
                  flex: 1,
                  '&:hover': {
                    backgroundColor: '#e7e7e7',
                  },
                }}
              >
                {fileName ? fileName : 'Sélectionner un média'}
              </ButtonBase>
            </label>
          </Grid>
          {formik.touched.image && formik.errors.image ? (
            <Typography
              variant="caption"
              sx={{
                color: '#ff453a',
                marginBottom: '7px',
                width: '170px',
                height: '29px',
                fontFamily: 'SFPro-Regular',
                fontSize: '12px',
                textAlign: 'left',
              }}
              data-testid="title"
            >
              {formik.errors.image}
            </Typography>
          ) : (
            selectedFile &&
            checkMediaSize(selectedFile?.size, formik.values.format) && (
              <Typography
                variant="caption"
                sx={{
                  color: '#ff453a',
                  marginBottom: '7px',
                  width: '170px',
                  height: '29px',
                  fontFamily: 'SFPro-Regular',
                  fontSize: '12px',
                  textAlign: 'left',
                }}
                data-testid="title"
              >
                Fichier trop large
              </Typography>
            )
          )}

          <Grid item sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Grid item sx={{ justifySelf: 'start', marginTop: '10px ' }}>
              <InputLabel htmlFor="startDate">Du</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
                <DatePicker
                  disablePast
                  inputFormat="dd/MM/yyyy"
                  components={{ OpenPickerIcon: DateIcon }}
                  value={formik.values.startDate}
                  onChange={(value) => {
                    formik.setFieldValue('startDate', value);
                  }}
                  renderInput={({ inputRef, inputProps, InputProps }) => (
                    <Stack
                      direction="row"
                      alignItems="center"
                      p={2}
                      sx={{
                        width: 'fit-content',
                        border: 'solid 1px #e7e7e7',
                        backgroundColor: '#ffffff',
                        input: {
                          border: 'none',
                        },
                      }}
                    >
                      <input
                        style={{ border: 'none', backgroundColor: '#FFFFFF', height: '20px', fontSize: '17px', width: '140px' }}
                        ref={inputRef}
                        {...inputProps}
                        disabled
                      />
                      {InputProps?.endAdornment}
                    </Stack>
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item sx={{ justifySelf: 'end', marginTop: '10px ' }}>
              <InputLabel htmlFor="endDate">Au</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
                <DatePicker
                  disablePast
                  inputFormat="dd/MM/yyyy"
                  components={{ OpenPickerIcon: DateIcon }}
                  value={formik.values.endDate}
                  onChange={(value) => {
                    formik.setFieldTouched('endDate');
                    formik.setFieldValue('endDate', value);
                  }}
                  renderInput={({ inputRef, inputProps, InputProps }) => (
                    <Stack
                      direction="row"
                      alignItems="center"
                      p={2}
                      sx={{
                        width: 'fit-content',
                        border: 'solid 1px #e7e7e7',
                        backgroundColor: '#ffffff',
                        input: {
                          border: 'none',
                        },
                      }}
                    >
                      <input
                        style={{ border: 'none', backgroundColor: '#FFFFFF', height: '20px', fontSize: '17px', width: '140px' }}
                        ref={inputRef}
                        {...inputProps}
                        disabled
                      />
                      {InputProps?.endAdornment}
                    </Stack>
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid item sx={{ display: 'flex', justifyContent: 'space-between' }}>
            {formik.touched.startDate && formik.errors.startDate && (
              <Typography
                variant="caption"
                sx={{
                  color: '#ff453a',
                  marginBottom: '7px',
                  width: '100%',
                  height: '29px',
                  fontFamily: 'SFPro-Regular',
                  fontSize: '12px',
                  textAlign: 'left',
                }}
                data-testid="startDate"
              >
                {formik.errors.startDate}
              </Typography>
            )}
            {formik.touched.endDate && formik.errors.endDate && (
              <Typography
                variant="caption"
                sx={{
                  color: '#ff453a',
                  width: '100%',
                  marginBottom: '7px',
                  fontFamily: 'SFPro-Regular',
                  fontSize: '12px',
                  textAlign: 'left',
                }}
                data-testid="endDate"
              >
                {formik.errors.endDate}
              </Typography>
            )}
          </Grid>
          <Grid item sx={{ marginTop: '10px ' }}>
            <Grid item sx={{ display: 'flex' }}>
              <InputLabel htmlFor="url">Url</InputLabel>
            </Grid>
            <InputBase
              id="url"
              sx={{ width: '100%' }}
              placeholder="www.urlapromouvoir.com"
              value={formik.values.url}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={!!formik.errors.url && formik.touched.url}
            />
          </Grid>
          {formik.touched.url && formik.errors.url && (
            <Typography
              variant="caption"
              sx={{
                color: '#ff453a',
                marginBottom: '7px',
                width: '170px',
                height: '29px',
                fontFamily: 'SFPro-Regular',
                fontSize: '12px',
                textAlign: 'left',
              }}
              data-testid="title"
            >
              {formik.errors.url}
            </Typography>
          )}
        </form>
        {compagniesState.error !== '' && <Typography sx={{ color: colors.Red, marginTop: '12px' }}> {compagniesState.error}</Typography>}
      </DialogContent>
      <DialogActions sx={{ height: '70px', marginTop: 0 }}>
        <ButtonBase
          sx={{
            color: '#a4a4a5',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            fontFamily: 'SFPro-Regular',
            fontSize: '17px',
            padding: '10px 24px',
          }}
          onClick={handleClose}
        >
          Annuler
        </ButtonBase>
        <button
          type="submit"
          form="createForm"
          className={!loader ? 'isRealsed ' : 'isActive '}
          disabled={selectedFile && checkMediaSize(selectedFile.size, formik.values.format)}
        >
          {!loader ? 'Créer' : <CheckIcon />}
        </button>
      </DialogActions>
    </Dialog>
  );
};
export default CreerCampagneDialog;
