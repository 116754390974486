/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import { yupResolver } from '@hookform/resolvers/yup';
import CreatableSelect from 'react-select/creatable';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import {
  Box,
  Grid,
  Stack,
  Button,
  CircularProgress,
  Container,
  Backdrop,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
  Typography,
} from '@mui/material';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import CustomLoadingButton from '../../../components/button/CustomLoadingButton';
import FormProvider from '../../../components/hook-form/FormProvider';
import RHFTextField from '../../../components/hook-form/RHFTextField';
import useSnackBar from '../../../hooks/useSnackBar';
import { RootState } from '../../../store/rootStore';
import MasterClass from '../../../models/MasterClass';
import { masterClassApi } from '../../../store/masterClass/api/MasterClass.api';
import { MasterClassFormProps } from './MasterClass-form.types';
import { CreateMasterClassSchema } from './create-masterclass.schema';
import AddPhotoIcon from '../../svgComponents/AddPhotoIcon';
import AdminVideoControl from '../../adds/menubar/pending/form/VideoControl';
import { clearNewTag, createTag, getTags } from '../../../store/tags/actions';
import { tagsActionTypes } from '../../../store/tags/models';
import { IMasterClassState, MasterClassActionTypes } from '../../../store/masterClass/slice/MasterClass-slice.types';
import { colors } from '../../../theme/sharedTheme';
import { getMasterClassAdds } from '../../../store/masterClassAds/actions';
import { masterClassAddsActionTypes } from '../../../store/masterClassAds/models';
import FullScreenModal from '../../adds/menubar/approved/form/FullScreenModal';

const { updateMasterClass, createMasterClass } = masterClassApi.endpoints;

interface TagOption {
  value: string;
  label: string;
}

// Example tag options

export default function MasterClassForm({ currentMasterClass, isUpdate, onClose, isEyeIconClicked }: MasterClassFormProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, MasterClassActionTypes>>();
  const dispatchTags = useDispatch<ThunkDispatch<RootState, undefined, tagsActionTypes>>();
  const [loading, setLoading] = useState<boolean>(false);
  const { data: dataaa, isLoading } = useSelector<RootState, IMasterClassState>((state: RootState) => state[masterClassApi.reducerPath]);
  const dispatchAds = useDispatch<ThunkDispatch<RootState, undefined, masterClassAddsActionTypes>>();
  const masterClassAdds = useSelector((state: RootState) => state.masterClassAdds.masterClassAdds);
  const tagList = useSelector((state: RootState) => state.tags.tags);
  const newTags = useSelector((state: RootState) => state.tags.newTag);
  const [tagOptionsData, setTagOptions] = useState<TagOption[]>([]);
  const [postURL, setPostURL] = useState<string | undefined>(undefined);
  const [postType, setPostType] = useState<string | undefined>(undefined);
  const [postError, setPostError] = useState<string | undefined>(undefined);
  const [videoURL, setVideoURL] = useState<string | undefined>(undefined);
  const [videoType, setVideoType] = useState<string | undefined>(undefined);
  const [isVideoPopupOpen, setVideoPopupOpen] = useState(false);
  const [learnImgURL, setLearnImgURL] = useState<(string | undefined)[]>([]);
  const [largeImgUrl, setLargeImgUrl] = useState<string | undefined>(undefined);
  const [openImgModel, setOpenImgModal] = useState(false);

  const defaultValues = useMemo<MasterClass>(
    () => ({
      title: currentMasterClass?.title || '',
      instructorName: currentMasterClass?.instructorName || '',
      description: currentMasterClass?.description || '',
      video: currentMasterClass?.video || '',
      tagIds: currentMasterClass?.tagIds || [],
      learningPoints: currentMasterClass?.learningPoints || [{ point: '', imageUrl: '', id: 0 }],
      isPaid: currentMasterClass?.isPaid !== undefined ? currentMasterClass?.isPaid : 1, // Ensure the correct value is set
      masterClassAdsId: currentMasterClass?.masterClassAdsId || 0, // Ensure the correct value is set
    }),
    [currentMasterClass],
  );

  const isPaidOption = [
    { label: 'Yes', value: 1 },
    { label: 'No', value: 0 },
  ];
  const methods = useForm<MasterClass>({
    resolver: yupResolver(CreateMasterClassSchema),
    defaultValues,
    mode: 'all',
  });

  const { alertSuccess, alertError } = useSnackBar();

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    trigger,
    clearErrors,
    setError,
    formState: { errors, isSubmitting },
  } = methods;

  const values = watch();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'learningPoints',
  });

  useEffect(() => {
    if (fields.length === 0) {
      append({ point: '', imageUrl: '', id: 0 }); // Add an initial field if none exist
    }
  }, [fields, append]);
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatchTags(getTags());
    }
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatchAds(getMasterClassAdds());
    }
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (!isUpdate) {
      reset(defaultValues);
    }
  }, [isUpdate, currentMasterClass]);

  useEffect(() => {
    if (isUpdate && currentMasterClass) {
      const posturl = currentMasterClass?.video || '';
      const postExtension = posturl.split('.').pop()?.toLowerCase() || '';
      setPostURL(posturl);
      setPostType(postExtension);
      const imageUrls = currentMasterClass?.learningPoints?.map((point) => point.imageUrl) || [];

      setLearnImgURL(imageUrls);
    }
  }, [currentMasterClass, isUpdate, setValue]);

  useEffect(() => {
    const updatedDefaultValues = {
      title: currentMasterClass?.title || '',
      instructorName: currentMasterClass?.instructorName || '',
      description: currentMasterClass?.description || '',
      video: currentMasterClass?.video || '',
      tagIds: currentMasterClass?.tagIds || [],
      learningPoints: currentMasterClass?.learningPoints || [{ point: '', imageUrl: '', id: 0 }],
      isPaid: currentMasterClass?.isPaid !== undefined ? currentMasterClass?.isPaid : 1, // Ensure the correct value is set
      masterClassAdsId: currentMasterClass?.masterClassAdsId || 0, // Ensure the correct value is set
    };
    methods.reset(updatedDefaultValues);
  }, [currentMasterClass, isUpdate]);

  const onSubmit = async () => {
    if (!isUpdate) {
      create();
    } else {
      update();
    }
  };
  console.log('values.learningPoints', values.learningPoints);
  const create = async () => {
    try {
      setLoading(true); // Start loading
      const isValid = await trigger();
      if (isValid) {
        const formData = new FormData();
        formData.append('title', values.title || '');
        formData.append('instructorName', values.instructorName || '');
        formData.append('description', values.description || '');
        formData.append('video', values.video || '');
        const learningPoints = values.learningPoints?.map((item) => item.point) ?? [];
        formData.append('learningPoints', String(learningPoints));
        values.learningPoints?.forEach((learningPoint, index) => {
          formData.append('learningPointsImages', learningPoint.imageUrl); // Appending each File object
        });
        formData.append('tagIds', String(values.tagIds));
        formData.append('isPaid', String(values.isPaid));
        formData.append('masterClassAdsId', String(values.masterClassAdsId));

        await dispatch(createMasterClass(formData));
        reset(defaultValues);
        dispatch(alertSuccess(t('masterClass.create_success_message')));
        dispatchTags(clearNewTag());
        onClose();
      }
    } catch (err) {
      dispatch(alertError(t('common.something_wrong_happend')));
      console.error(err);
    } finally {
      setLoading(false); // End loading
    }
  };
  console.log('learningPoints', values.learningPoints, currentMasterClass?.learningPoints);

  const update = async () => {
    try {
      setLoading(true); // Start loading
      const isValid = await trigger();
      if (isValid && currentMasterClass?.id && !postError) {
        const formData = new FormData();
        formData.append('title', values.title || '');
        formData.append('description', values.description || '');
        formData.append('isPaid', String(values.isPaid));
        formData.append('masterClassAdsId', String(values.masterClassAdsId));
        values.tagIds?.forEach((learningPoint) => {
          formData.append('tagIds[]', String(learningPoint)); // Appending each File object
        });
        if (typeof values?.video !== 'string') {
          // If imageUrl is a string, append an empty string
          formData.append('video', values.video || '');
        }

        values.learningPoints?.forEach((learningPoint, index) => {
          if (typeof learningPoint?.imageUrl !== 'string') {
            // If imageUrl is a string, append an empty string
            formData.append(`learningPointsImages`, learningPoint.imageUrl);
          }
        });

        values.learningPoints?.forEach((learningPoint, index) => {
          const isOldLearningPoint = currentMasterClass.learningPoints?.some((oldPoint) => oldPoint.id === learningPoint.id);

          // Determine if it's an old or new learning point
          const isNew = isOldLearningPoint ? 0 : 1;

          const learningPointData = {
            id: learningPoint.id || 0, // If there's an id field
            point: learningPoint.point || '',
            isNew, // Add isNew flag (0 for old, 1 for new)
          };

          // const learningPoints = values.learningPoints?.map((item) => item.point) ?? [];
          formData.append(`learningPoints[${index}][id]`, String(learningPointData.id));
          formData.append(`learningPoints[${index}][point]`, learningPointData.point);
          formData.append(`learningPoints[${index}][isNew]`, String(learningPointData.isNew));
          if (typeof learningPoint?.imageUrl !== 'string') {
            // If imageUrl is a string, append an empty string
            formData.append(`learningPoints[${index}][image]`, learningPoint.imageUrl);
          }
        });

        await dispatch(updateMasterClass(currentMasterClass?.id, formData));
        reset(defaultValues);
        dispatch(alertSuccess(t('masterClass.update_success_message')));
        dispatchTags(clearNewTag());
        onClose();
      }
    } catch (err) {
      dispatch(alertError(t('common.something_wrong_happend')));
      console.error(err);
    } finally {
      setLoading(false); // End loading
    }
  };

  const openVideoPopup = () => {
    setVideoURL(postURL);
    setVideoType(postType);
    setVideoPopupOpen(true);
  };

  const handleLogoUpload = (e: any) => {
    const logoFile = e.target.files[0];
    const imageUrl = URL.createObjectURL(logoFile);
    const fileType = logoFile?.type.split('/')[1];
    if (logoFile) {
      if (logoFile.type.includes('video')) {
        const videoElement = document.createElement('video');
        const url = URL.createObjectURL(logoFile);

        videoElement.src = url;
        videoElement.onloadedmetadata = () => {
          const duration = videoElement.duration;
          // Check if the video exceeds 10 seconds
          if (duration < 10) {
            // Video is too long
            setPostError(t('advertisements.master_file_size_error'));
            setPostURL(undefined);
            setPostType(undefined);
          } else {
            // Video is within the allowed duration
            setPostError(undefined);
            setPostURL(imageUrl || undefined);
            setPostType(fileType);
            setValue('video', logoFile); // Update form state with video file
            clearErrors('video'); // Clear form error for video
          }
          // Revoke the object URL after the check
          URL.revokeObjectURL(url);
        };
      } else {
        setPostError('Please choose an video file.');
        setPostURL(undefined);
        setPostType(undefined);
      }
    }
  };

  const closeVideoPopup = () => {
    setVideoPopupOpen(false);
  };

  const convertToTagOptions = (tagIds: string[] | undefined): TagOption[] => {
    if (!tagIds) return [];
    return tagIds?.map((id) => {
      const tagVal = tagList?.find((tag) => String(tag.id) === id);
      return { value: id, label: tagVal ? tagVal.name : '' };
    });
  };

  useEffect(() => {
    const initialTagOptions: TagOption[] = (tagList || [])?.map((tag) => ({
      value: String(tag.id),
      label: tag.name,
    }));
    setTagOptions(initialTagOptions);
  }, [tagList]);

  useEffect(() => {
    if (newTags?.masterClassTag) {
      const newTagId = newTags.masterClassTag.id;
      const newTagLabel = newTags.masterClassTag.name;

      // Ensure newTagId is defined
      if (newTagId !== undefined) {
        // Create new tag option
        const newTagOption: TagOption = { value: newTagId.toString(), label: newTagLabel };

        // Update tag list with the new tag
        const updatedTagList = [...(tagList || []), { id: newTagId, name: newTagLabel }];

        // Exclude the newly created tag option from the options list
        const updatedTagOptions: TagOption[] = updatedTagList
          ?.filter((tag): tag is { id: string; name: string } => 'id' in tag && 'name' in tag) // Type guard for valid tags
          ?.filter((tag) => tag.id !== newTagId) // Exclude the newly created tag
          ?.map((tag) => ({
            value: tag.id.toString(), // Ensure value is a string
            label: tag.name,
          }));

        // Update form values with the newly created tag
        const currentTagIds: string[] = watch('tagIds') || [];
        if (!currentTagIds.includes(newTagId.toString())) {
          setValue('tagIds', [...currentTagIds, newTagId.toString()], { shouldValidate: true });
        }

        // Update Data with the updatedTagOptions
        setTagOptions([...updatedTagOptions, newTagOption]);

        // Optionally update your tagList in Redux or local state if needed
        // dispatch(updateTagList(updatedTagList));
      }
    }
  }, [newTags, setValue, watch, tagList]);

  const createNewTag = useCallback(
    async (newTagName: string) => {
      try {
        await dispatch(createTag(newTagName));
        dispatch(alertSuccess(t('masterClass.create_tag_success_message')));
      } catch (err) {
        dispatch(alertError(t('masterClass.create_tag_error_message')));
        console.error(err);
      }
    },
    [dispatch, t, values.tagIds, setValue, alertSuccess, alertError],
  );

  const handleClickOpenModal = (imageurl: string) => {
    setLargeImgUrl(imageurl || '');
    setOpenImgModal(true);
  };

  const handleClickCloseImgModal = () => {
    setLargeImgUrl('');
    setOpenImgModal(false);
  };

  const handleImageUpload = (index: number, event: any) => {
    const selectedFile = event.target.files?.[0];

    if (selectedFile && selectedFile.type.includes('image')) {
      const imageUrl = URL.createObjectURL(selectedFile);
      setValue(`learningPoints.${index}.imageUrl`, selectedFile); // Store the File object
      setLearnImgURL((prev) => {
        const updated = [...prev];
        updated[index] = imageUrl;
        return updated;
      });
      clearErrors(`learningPoints.${index}.imageUrl`);
    } else {
      setError(`learningPoints.${index}.imageUrl`, {
        type: 'manual',
        message: 'Please choose a image file.', // Set error message for the video field
      });
      setLearnImgURL([]);
    }
  };
  console.log('Fsfdsfs', errors);
  return (
    <>
      <Backdrop
        sx={{
          color: colors.yellow,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          zIndex: (theme) => theme.zIndex.drawer + 1, // Ensure this value is high enough
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
        <h2>{t('common.loading')}</h2>
      </Backdrop>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid className={`artgapi-modal`} container spacing={3}>
          <Grid className="artgapi-plan-form" item xs={12} md={12}>
            <Box sx={{ p: 3 }}>
              <Box sx={{ mb: 3 }}>
                <Box
                  sx={{
                    display: 'grid',
                    columnGap: 2,
                    rowGap: 3,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                  }}
                >
                  <RHFTextField
                    name={'title'}
                    placeholder={t('masterClass.form_fields.title.placeholder')}
                    label={t('masterClass.form_fields.title.label')}
                    disabled={isEyeIconClicked}
                  />
                  <RHFTextField
                    name={'instructorName'}
                    placeholder={t('masterClass.form_fields.instructorName.placeholder')}
                    label={t('masterClass.form_fields.instructorName.placeholder')}
                    disabled={isEyeIconClicked || !!isUpdate}
                  />

                  <FormControl fullWidth>
                    <InputLabel
                      id="ispaid-label"
                      sx={{
                        transform: 'translate(0px, 0px) scale(0.95)',
                        position: 'relative !important',
                        maxWidth: '0',
                        minWidth: '100%',
                        color: 'rgb(171, 171, 171)',
                        marginBottom: '0.5rem',

                        '& .css-to7sdl-MuiFormControl-root': {
                          marginTop: '4px !important',
                        },
                      }}
                    >
                      {t('masterClass.form_fields.ispaid.placeholder')}
                    </InputLabel>
                    <Controller
                      name="isPaid"
                      control={methods.control}
                      render={({ field, fieldState: { error } }) => (
                        <>
                          <Select
                            {...field}
                            sx={{
                              width: '100%',
                              height: '48px',
                              borderRadius: '0',
                              '& .MuiSelect-select': {
                                width: '100%',
                                height: '48px !important',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '12.5px 14px',
                                paddingRight: '110px !important',
                              },
                              '& .MuiOutlinedInput-root': {
                                marginTop: '-8px !important',
                              },
                              '& .MuiOutlinedInput-input': {
                                border: isEyeIconClicked ? '1px solid #e7e7e7' : '1px solid #0d0d0d',
                                borderColor: error && '#d32f2f',
                                borderRadius: '0',
                              },
                              '& .MuiSelect-select:hover': {
                                borderColor: '#141415 !important',
                              },
                            }}
                            labelId="ispaid-label"
                            id="id"
                            value={field.value}
                            onChange={(e) => field.onChange(e.target.value)}
                            disabled={isEyeIconClicked}
                          >
                            {/* Map over the list of countries */}
                            {isPaidOption?.map((item) => (
                              <MenuItem key={item.value} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Select>
                          {/* Display error message if there's an error for countryId */}
                        </>
                      )}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel
                      id="masterClassAdsId-label"
                      sx={{
                        transform: 'translate(0px, 0px) scale(0.95)',
                        position: 'relative !important',
                        maxWidth: '0',
                        minWidth: '100%',
                        color: 'rgb(171, 171, 171)',
                        marginBottom: '0.5rem',

                        '& .css-to7sdl-MuiFormControl-root': {
                          marginTop: '4px !important',
                        },
                      }}
                    >
                      {t('masterClass.form_fields.masterClassAds.placeholder')}
                    </InputLabel>
                    <Controller
                      name="masterClassAdsId"
                      control={methods.control}
                      render={({ field, fieldState: { error } }) => (
                        <>
                          <Select
                            {...field}
                            sx={{
                              width: '100%',
                              height: '48px',
                              borderRadius: '0',
                              '& .MuiSelect-select': {
                                width: '100%',
                                height: '48px !important',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '12.5px 14px',
                              },
                              '& .MuiOutlinedInput-root': {
                                marginTop: '-8px !important',
                              },
                              '& .MuiOutlinedInput-input': {
                                border: isEyeIconClicked ? '1px solid #e7e7e7' : '1px solid #0d0d0d',
                                borderColor: error && '#d32f2f',
                                borderRadius: '0',
                              },
                              '& .MuiSelect-select:hover': {
                                borderColor: '#141415 !important',
                              },
                            }}
                            labelId="masterClassAdsId-label"
                            id="masterClassAdsId"
                            value={field.value}
                            onChange={(e) => field.onChange(e.target.value)}
                            disabled={isEyeIconClicked}
                          >
                            <MenuItem key="select" selected value="0">
                              {t('common.masterClassAds')}
                            </MenuItem>
                            {/* Map over the list of countries */}
                            {masterClassAdds?.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.title}
                              </MenuItem>
                            ))}
                          </Select>
                          {/* Display error message if there's an error for countryId */}
                          {error && (
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: '0.75rem',
                                lineHeight: '1.66',
                                letterSpacing: '0.03333em',
                                textAlign: 'left',
                                marginTop: '3px',
                                marginRight: '14px',
                                marginBottom: '0',
                                marginLeft: '14px',
                                color: '#d32f2f',
                              }}
                            >
                              {t(`${error?.message}`)}
                            </Typography>
                          )}
                        </>
                      )}
                    />
                  </FormControl>
                </Box>
              </Box>
              <Box sx={{ mb: 3 }}>
                <Box
                  sx={{
                    display: 'grid',
                    columnGap: 2,
                    rowGap: 3,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                  }}
                >
                  <RHFTextField
                    placeholder={t('masterClass.form_fields.description.placeholder')}
                    name={'description'}
                    label={t('masterClass.form_fields.description.label')}
                    disabled={isEyeIconClicked}
                    multiline
                    sx={{
                      '& .MuiOutlinedInput-input': {
                        border: '0 !important',
                      },
                    }}
                    rows={4} // Adjust the number of rows based on your preference
                  />
                </Box>
              </Box>
              <Box sx={{ mb: 3 }}>
                <Grid>
                  <div>
                    <label className="MuiInputLabel-root MuiInputLabel-animated MuiFormLabel-root MuiFormLabel-colorPrimary css-r440dj-MuiFormLabel-root-MuiInputLabel-root">
                      {t('masterClass.form_fields.video.label')}
                    </label>
                    <div style={{ display: 'flex', marginBottom: '0', alignItems: 'center' }}>
                      {postURL ? (
                        <div
                          style={{
                            width: '100px',
                            height: '100px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'relative',
                          }}
                        >
                          <video
                            style={{ cursor: 'pointer', width: '100%', objectFit: 'cover', height: '100%' }}
                            src={postURL}
                            onClick={openVideoPopup}
                          ></video>
                          <span
                            style={{
                              backgroundColor: 'white',
                              display: 'flex',
                              alignItems: 'center',
                              borderRadius: '9999px',
                              position: 'absolute',
                              width: '1.5rem',
                              height: '1.5rem',
                              cursor: 'pointer',
                              justifyContent: 'center',
                            }}
                          >
                            <PlayCircleOutlineIcon className="text-base" onClick={openVideoPopup} />
                          </span>
                        </div>
                      ) : (
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '0px' }}>
                          <label
                            htmlFor="logos"
                            style={{
                              width: '44px',
                              height: '44px',
                              minWidth: '44px',
                              backgroundColor: '#f0f0f0',
                              border: '1px dashed #ccc',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <AddPhotoIcon />
                          </label>
                        </div>
                      )}
                      {!isEyeIconClicked && (
                        <Button
                          className="artgapi-upload-btn"
                          style={{
                            textTransform: 'capitalize',
                            boxShadow: 'inset 0px -1px 0px #E7E7E7',
                            marginLeft: '15px',
                            backgroundColor: '#ffcc33',
                          }}
                          size="small"
                          variant="contained"
                          component="label"
                          disabled={isEyeIconClicked}
                        >
                          {t('articles.upload')}
                          <input hidden type="file" id="video-url" onChange={handleLogoUpload} accept="video/*" />
                        </Button>
                      )}
                    </div>
                    {errors?.video && !postError && (
                      <p style={{ color: '#d32f2f', marginTop: '0.5rem', fontSize: '0.75rem' }}>
                        {t('masterClass.form_fields.video.required_error_message')}
                      </p>
                    )}
                    {postError && <p style={{ color: '#d32f2f', marginTop: '0.5rem', fontSize: '0.75rem' }}>{postError}</p>}
                  </div>
                </Grid>
              </Box>

              <Box sx={{ mb: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <label className="MuiInputLabel-root MuiInputLabel-animated MuiFormLabel-root MuiFormLabel-colorPrimary css-r440dj-MuiFormLabel-root-MuiInputLabel-root">
                      {t('masterClass.form_fields.learningPoints.label')}
                    </label>
                    {fields?.map((field, index) => (
                      <Box
                        key={field.id}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          mb: 1,
                          gap: 1,
                        }}
                        className={errors?.learningPoints?.[index]?.imageUrl?.message ? 'error-learnpoint-label' : 'learnpoint-label'}
                      >
                        <RHFTextField
                          name={`learningPoints[${index}].point`}
                          placeholder={t('masterClass.form_fields.learningPoints.placeholder')}
                          disabled={isEyeIconClicked}
                          sx={{ flex: 1 }}
                        />
                        {/* <Grid item xs={4}>
                          <input type="file" accept="image/*" onChange={(e) => handleImageUpload(index, e)} />
                          {errors?.learningPoints?.[index]?.image && (
                            <p style={{ color: '#d32f2f', marginTop: '0.5rem', fontSize: '0.75rem' }}>
                              {t('masterClass.form_fields.learningPoints.image_required_message')}
                            </p>
                          )}
                        </Grid> */}

                        <div style={{ display: 'flex', marginBottom: '0', alignItems: 'center', flexWrap: 'wrap' }}>
                          {learnImgURL[index] ? (
                            <>
                              <div>
                                <span>
                                  <img
                                    src={learnImgURL[index] || ''}
                                    alt="img"
                                    style={{
                                      width: '100px',
                                      height: '48px',
                                      objectFit: 'contain',
                                      cursor: 'pointer',
                                    }}
                                    onChange={(e) => handleImageUpload(index, e)}
                                    onClick={() => handleClickOpenModal(learnImgURL[index] || '')}
                                  />
                                </span>
                                {/* <label className="text-[#5e5e5e] break-all !mb-0">{'gdfgdfh'}</label> */}
                              </div>
                            </>
                          ) : (
                            <>
                              <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '0px' }}>
                                <label
                                  htmlFor="logoss"
                                  style={{
                                    width: '44px',
                                    height: '44px',
                                    minWidth: '44PX',
                                    backgroundColor: '#f0f0f0',
                                    border: '1px dashed #ccc',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginBottom: '0',
                                  }}
                                >
                                  <AddPhotoIcon />
                                </label>
                              </div>
                            </>
                          )}
                          {!isEyeIconClicked && (
                            <Button
                              className="artgapi-upload-btn"
                              style={{
                                textTransform: 'capitalize',
                                boxShadow: 'inset 0px -1px 0px #E7E7E7',
                                marginLeft: '15px',
                                backgroundColor: isEyeIconClicked ? '#ffcc33' : '#ffcc33',
                              }}
                              size="small"
                              variant="contained"
                              component="label"
                            >
                              {t('articles.upload')}
                              <input hidden type="file" name="" id="img-post" onChange={(e) => handleImageUpload(index, e)} />
                            </Button>
                          )}
                          {errors?.learningPoints?.[index]?.imageUrl && (
                            <p style={{ color: '#d32f2f', marginTop: '0.5rem', fontSize: '0.75rem' }}>
                              {errors?.learningPoints?.[index]?.imageUrl?.message}
                            </p>
                          )}
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', alignSelf: 'center' }}>
                          {index > 0 && (
                            <span onClick={() => remove(index)} style={{ cursor: 'pointer' }}>
                              <RemoveCircleIcon />
                            </span>
                          )}
                          {!isEyeIconClicked && index === fields.length - 1 && (
                            <span onClick={() => append({ point: '', imageUrl: '', id: 0 })} style={{ cursor: 'pointer' }}>
                              <AddCircleIcon />
                            </span>
                          )}
                        </div>
                      </Box>
                    ))}
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ mb: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <label className="MuiInputLabel-root MuiInputLabel-animated MuiFormLabel-root MuiFormLabel-colorPrimary css-r440dj-MuiFormLabel-root-MuiInputLabel-root">
                      {t('masterClass.form_fields.tags.label')}
                    </label>

                    <Controller
                      control={control}
                      name="tagIds"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <CreatableSelect
                          isMulti
                          onChange={(selectedOptions: any) => {
                            const selectedTagIds = selectedOptions ? selectedOptions.map((option: any) => option.value) : [];
                            setValue('tagIds', selectedTagIds, { shouldValidate: true }); // Update form state and re-trigger validation
                          }}
                          onCreateOption={createNewTag}
                          value={convertToTagOptions(value)}
                          options={tagOptionsData}
                          placeholder={t('masterClass.form_fields.tags.placeholder')}
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              borderColor: errors.tagIds ? '#d32f2f' : provided.borderColor, // Set border color based on error
                            }),
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                {errors?.tagIds && (
                  <p style={{ color: '#d32f2f', marginTop: '0.5rem', fontSize: '0.75rem' }}>
                    {t('masterClass.form_fields.tags.required_error_message')}
                  </p>
                )}
              </Box>

              {/* Submit Button */}
              {!isEyeIconClicked && (
                <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                  <CustomLoadingButton
                    loading={loading || isSubmitting}
                    label={!isUpdate ? t('interests.create_button_label') : t('interests.update_button_label')}
                    onClick={onSubmit}
                  />
                </Stack>
              )}
            </Box>
          </Grid>
        </Grid>
      </FormProvider>

      {isVideoPopupOpen && (
        <AdminVideoControl
          isVideoPopupOpen={isVideoPopupOpen}
          closeVideoPopup={closeVideoPopup}
          videourl={videoURL || ''}
          selectedVideo={videoType || ''}
        />
      )}
      {openImgModel && <FullScreenModal openImgModel={openImgModel} closeImgPopup={handleClickCloseImgModal} largeImgUrl={largeImgUrl || ''} />}
    </>
  );
}
