/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Button, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CONSTANTS } from '../../../utils';
import DeleteIcon from '../../svgComponents/deleteIcon';
import ConfirmDeleteDialog from '../deleteDialog/confirmDeleteDialog';

interface ArticleDialogHeader {
  onClose: () => void;
  data: any;
  moduleName?: string;
  onSubmitHandler: any;
  isUpdate?: boolean;
}
export const ArticleDialogHeader = (props: ArticleDialogHeader) => {
  const { onClose, data, moduleName, onSubmitHandler, isUpdate } = props;
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const { t } = useTranslation();
  const titleArticleFormat = data.type === '1' ? CONSTANTS.ARTICLE : CONSTANTS.PODCAST;

  const handleConfirmDelete = () => {
    setConfirmDelete(true);
  };
  const handleCloseDeleteDialog = () => {
    setConfirmDelete(false);
  };

  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h6" sx={{ ml: 4, mt: 2 }}>
            {moduleName == CONSTANTS.Opportunities ? data.title : data.title + '/' + titleArticleFormat}
          </Typography>
        </Grid>

        <Grid item>
          <Button style={{ color: '#a4a4a5', width: '104px', height: '84px' }} onClick={onClose}>
            <Typography style={{ fontSize: '17px', textTransform: 'capitalize' }}>{CONSTANTS.ANNULER}</Typography>
          </Button>
          {isUpdate && (
            <Button
              style={{
                backgroundColor: '#FF453A',
                color: 'black',
                width: '84.1px',
                height: '84px',
                borderRadius: 0,
                opacity: data?.status == CONSTANTS.DELETED ? '0.5' : '1',
              }}
              disabled={data?.status == CONSTANTS.DELETED}
              onClick={handleConfirmDelete}
            >
              <DeleteIcon />
            </Button>
          )}
          <Button
            style={{
              backgroundColor: '#ffcc33',
              color: 'black',
              width: '104px',
              height: '84px',
              borderRadius: 0,
              opacity: data?.status == CONSTANTS.DELETED ? '0.5' : '1',
              textTransform: 'capitalize',
            }}
            type="submit"
            disabled={data?.status == CONSTANTS.DELETED}
            onClick={onSubmitHandler}
          >
            <Typography style={{ fontSize: '17px' }}>
              {moduleName == CONSTANTS.ARTICLE ? t('common.published') : t('common.save_and_publish')}
            </Typography>
          </Button>
        </Grid>
      </Grid>
      {confirmDelete && (
        <ConfirmDeleteDialog
          show={confirmDelete}
          moduleName={moduleName}
          id={data.id}
          closeDialog={handleCloseDeleteDialog}
          onCloseSecondDialog={onClose}
        />
      )}
    </>
  );
};

export default ArticleDialogHeader;
