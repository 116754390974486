/* eslint-disable @typescript-eslint/no-explicit-any*/
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { theme } from '../../../theme/theme';
import { ButtonBase, IconButton } from '@mui/material';
import CloseIcon from '../../svgComponents/closeIcon';
import { CONSTANTS } from '../../../utils';
import { changeArticleStatus } from '../../../store/articles/action';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../../store/rootStore';
import { ArticlesActionTypes } from '../../../store/articles/models';
import { PaginationActionTypes } from '../../../store/pagination/models';
import { deleteOpportunity } from '../../../store/opportunities/action';
import { useTranslation } from 'react-i18next';
interface ConfirmDeleteDialogProps {
  show: boolean;
  id: number;
  moduleName?: string;
  closeDialog: any;
  onCloseSecondDialog?: any;
}
export const ConfirmDeleteDialog = (props: ConfirmDeleteDialogProps) => {
  const { show, moduleName, closeDialog, id, onCloseSecondDialog } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, ArticlesActionTypes | PaginationActionTypes>>();
  const deleteHandler = () => {
    switch (moduleName) {
      case CONSTANTS.Opportunities:
        dispatch(deleteOpportunity(id));
        break;
      case CONSTANTS.ARTICLE:
        dispatch(changeArticleStatus(id));
        break;
    }
    closeDialog();
    onCloseSecondDialog();
  };
  return (
    <Dialog
      open={show}
      onClose={closeDialog}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '500px',
            maxWidth: '500px',
            borderRadius: 0,
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          width: '500px',
          color: theme.palette.primary.main,
          fontFamily: 'SFPro-Regular',
          backgroundColor: theme.palette.secondary.main,
          textAlign: 'center',
        }}
      >
        <IconButton sx={{ position: 'absolute', right: 5, top: 5 }} onClick={closeDialog}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogTitle
        sx={{
          width: '500px',
          fontSize: '24px',
          color: theme.palette.primary.main,
          fontFamily: 'SFPro-Regular',
          backgroundColor: theme.palette.secondary.main,
          textAlign: 'center',
        }}
      >
        ATTENTION
      </DialogTitle>

      <DialogTitle
        sx={{
          width: '500px',
          fontFamily: 'SFPro-Regular',
          backgroundColor: theme.palette.secondary.main,
          fontSize: '14px',
          textAlign: 'center',
          color: ' #A4A4A5',
          alignSelf: 'center',
          justifySelf: 'center',
          paddingTop: 0,
        }}
      >
        {moduleName === CONSTANTS.Opportunities ? t('opportunities.delete_message') : t('articles.delete_message')}
      </DialogTitle>
      <DialogActions sx={{ width: '100%', height: '100px ' }}>
        <ButtonBase
          sx={{
            color: '#a4a4a5',
            height: '42px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            fontFamily: 'SFPro-Regular',
            fontSize: '17px',
            padding: '16px 24px',
          }}
          onClick={closeDialog}
        >
          Annuler
        </ButtonBase>
        <ButtonBase
          sx={{
            marginRight: '32px ',
            height: '52px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#FF453A',
            fontFamily: 'SFPro-Regular',
            fontSize: '17px',
            padding: '16px 24px',
          }}
          onClick={deleteHandler}
        >
          Supprimer
        </ButtonBase>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDeleteDialog;
