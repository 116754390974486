import * as yup from 'yup';

export const CreateMasterClassSchema = yup.object().shape({
  title: yup
    .string()
    .trim()
    .max(50, 'masterClass.form_fields.title.max_length_error_message')
    .required('masterClass.form_fields.title.required_error_message'),
  description: yup.string().trim().required('masterClass.form_fields.description.required_error_message'),
  video: yup.string().required('masterClass.form_fields.video.required_error_message'),
  tagIds: yup.array().min(1, 'masterClass.form_fields.tags.max_length_error_message').required('masterClass.form_fields.tags.required_error_message'),
  learningPoints: yup
    .array()
    .of(
      yup.object().shape({
        point: yup.string().trim().required('masterClass.form_fields.learningPoints.required_error_message'),
        imageUrl: yup.string().trim().required(`Ce que vous allez apprendre, le champ d'image est requis`),
      }),
    )
    .min(1, 'masterClass.form_fields.learningPoints.max_length_error_message')
    .required('masterClass.form_fields.learningPoints.required_error_message'),
});
