import HeaderProperty from '../../models/Table';

export const adminHeader: HeaderProperty[] = [
  {
    label: 'NOM ET PRÉNOM',
    type: 'normal',
    height: 50,
    width: '50%',
    value: 'fullname',
  },
  {
    label: "NOM D'UTILISATEUR",
    type: 'normal',
    height: 50,
    width: '50%',
    value: 'nickname',
  },
];

export const artistsHeader: HeaderProperty[] = [
  {
    label: 'NOM PRENOM',
    type: 'normal',
    height: 50,
    width: '33%',
    value: 'fullname',
  },
  {
    label: "NOM D'UTILISATEUR",
    type: 'normal',
    height: 50,
    width: '33%',
    value: 'nickname',
  },
  {
    label: 'ABONNEMENT',
    type: 'normal',
    height: 50,
    width: '33%',
    value: 'Subscriptions',
  },
];

export const artLoversHeader: HeaderProperty[] = [
  {
    label: 'NOM PRENOM',
    type: 'normal',
    height: 50,
    width: '33%',
    value: 'fullname',
  },
  {
    label: "NOM D'UTILISATEUR",
    type: 'normal',
    height: 50,
    width: '33%',
    value: 'nickname',
  },
  {
    label: 'ABONNEMENT',
    type: 'normal',
    height: 50,
    width: '33%',
    value: 'Subscriptions',
  },
];

export const galerieHeader: HeaderProperty[] = [
  {
    label: 'Nom',
    type: 'normal',
    height: 50,
    width: '50%',
    value: 'galleryName',
  },
  {
    label: 'ABONNEMENT',
    type: 'normal',
    height: 50,
    width: '50%',
    value: 'Subscriptions',
  },
];

export const adsUserHeader: HeaderProperty[] = [
  {
    label: 'Nom',
    type: 'normal',
    height: 50,
    width: '50%',
    value: 'galleryName',
  },
  {
    label: 'Email',
    type: 'normal',
    height: 50,
    width: '50%',
    value: 'Subscriptions',
  },
];

export const rowDescriptionAdminHeader = ['black', 'black'];
export const rowDescriptionArtistsHeader = ['black', 'black', 'colored'];
export const rowDescriptiongalerieHeader = ['black', 'colored'];
