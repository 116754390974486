/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Menu, MenuItem, TableCell, TableHead, TableRow, ThemeProvider, Typography } from '@mui/material';
import { tableHeaderTheme } from '../../../theme/table-header-theme';
import ArrowComponent from '../../svgComponents/ArrowComponent';
import { colors } from '../../../theme/sharedTheme';
import CheckedIcon from '../../svgComponents/CheckedIcon';
import { allowedItemTypes } from '../../../utils/constants';
import { useTranslation } from 'react-i18next';
import HeaderProperty from '../../../models/Table';
interface TableHeadProps {
  items: HeaderProperty[];
  handleFilter?: (value: string) => void;
  selectType?: (value: string) => void;
  checked?: boolean;
  statusTable?: string;
  type?: string;
}

/**
 *component returning the head of the table
 *
 *
 */
const choices: { [key: string]: { value: string; id: number } } = {
  ['Tous']: {
    value: '10',
    id: 10,
  },
  ['open']: {
    value: '20',
    id: 20,
  },
  ['closed']: {
    value: '30',
    id: 30,
  },
  ['treated']: {
    value: '40',
    id: 40,
  },
  ['reported']: {
    value: '50',
    id: 50,
  },
  ['DRAFT']: {
    value: '20',
    id: 20,
  },
  ['PUBLISHED']: {
    value: '30',
    id: 30,
  },
  ['DELETE']: {
    value: '40',
    id: 40,
  },
};

const choicesTypes: { [key: string]: { value: string; id: number } } = {
  ['Tous']: {
    value: '10',
    id: 10,
  },
  ['user']: {
    value: '40',
    id: 40,
  },
  ['comment']: {
    value: '20',
    id: 20,
  },
  ['publication']: {
    value: '30',
    id: 30,
  },
  ['articleComment']: {
    value: '50',
    id: 50,
  },
  ['Article']: {
    value: '20',
    id: 20,
  },
  ['Podcast']: {
    value: '30',
    id: 30,
  },
  ['0']: {
    value: '30',
    id: 30,
  },
  ['1']: {
    value: '20',
    id: 20,
  },
};
export const HeadTable = (props: TableHeadProps) => {
  const { items, handleFilter, statusTable, type } = props;
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [openType, setOpenType] = useState(false);
  const [anchorElm, setAnchorElm] = useState(null);
  const [anchorElmType, setAnchorElmType] = useState(null);
  const [checked, setChecked] = React.useState<number>(choices[statusTable ?? 'Tous']?.id ?? 10);
  const [checkedType, setCheckedType] = React.useState<number>(choicesTypes[type ?? 'Tous']?.id ?? 10);

  useEffect(() => {
    setChecked(choices[statusTable ?? 'Tous']?.id ?? 10);
    setCheckedType(choicesTypes[type ?? 'Tous']?.id ?? 10);
  }, [statusTable, type]);

  const handleClick = (e: any) => {
    setAnchorElm(e.currentTarget);
    setOpen(true);
  };
  const handleClose = () => {
    setAnchorElm(null);
    setOpen(false);
  };
  const handleClickType = (e: any) => {
    setAnchorElmType(e.currentTarget);
    setOpenType(true);
  };
  const handleCloseType = () => {
    setAnchorElmType(null);
    setOpenType(false);
  };
  return (
    <>
      <ThemeProvider theme={tableHeaderTheme}>
        <TableHead>
          <TableRow tabIndex={-1}>
            {items.map((item: HeaderProperty, index: number) => (
              <TableCell sx={{ height: item.height, width: item.width }} align="left" key={`table-row-head-${index}`}>
                {allowedItemTypes.includes(item.type) ? (
                  <Typography sx={{ textTransform: 'uppercase', color: colors.MAIN_GREY, marginLeft: '0px' }}>{item.label}</Typography>
                ) : (item.type === 'select' && item.value === 'type') || item.value === 'category' ? (
                  <>
                    <MenuItem onClick={handleClickType} style={{ color: colors.MAIN_GREY, marginLeft: '-10%', textTransform: 'uppercase' }}>
                      {t(item.label)} <ArrowComponent />
                    </MenuItem>
                    <Menu anchorEl={anchorElmType} onClose={handleCloseType} open={openType}>
                      {item.choicesTypes &&
                        item.choicesTypes.map((selectChoice: any, choiceIndex: number) => (
                          <MenuItem
                            key={`item-${choiceIndex}`}
                            value={t(selectChoice.value)}
                            onClick={() => {
                              setCheckedType(selectChoice.id);
                              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                              handleFilter && handleFilter(t(selectChoice.label));
                              setOpenType(false);
                            }}
                          >
                            <div style={{ width: '12px', marginRight: '15px' }}>
                              {checkedType && selectChoice.id === checkedType ? <CheckedIcon key={`item-${choiceIndex}`} /> : null}
                            </div>
                            <div> {t(selectChoice.label)}</div>
                          </MenuItem>
                        ))}
                    </Menu>
                  </>
                ) : (
                  <>
                    <MenuItem onClick={handleClick} style={{ color: colors.MAIN_GREY, marginLeft: '-10%', textTransform: 'uppercase' }}>
                      {item.label} <ArrowComponent />
                    </MenuItem>
                    <Menu anchorEl={anchorElm} onClose={handleClose} open={open}>
                      {item.choices &&
                        item.choices.map((selectChoice: any, choiceIndex: number) => (
                          <MenuItem
                            key={`item-${choiceIndex}`}
                            value={t(selectChoice.value)}
                            onClick={() => {
                              setChecked(selectChoice.id);
                              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                              handleFilter ? handleFilter(t(selectChoice.label)) : '';
                              setOpen(false);
                            }}
                          >
                            <div style={{ width: '12px', marginRight: '15px' }}>
                              {checked && selectChoice.id === checked ? <CheckedIcon key={`item-${choiceIndex}`} /> : null}
                            </div>
                            <div> {t(selectChoice.label)}</div>
                          </MenuItem>
                        ))}
                    </Menu>
                  </>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      </ThemeProvider>
    </>
  );
};
