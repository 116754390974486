/* eslint-disable @typescript-eslint/no-explicit-any*/
import { ThunkDispatch } from 'redux-thunk';
import { AuthActionTypes, LoginInterface } from './models';
import { RootState } from '../rootStore';
import { LOGIN_ATTEMPT, LOGIN_ERROR, LOGIN_SUCCESS, LOGOUT_ATTEMPT, LOGOUT_ERROR, LOGOUT_SUCCESS } from './types';
import { ENDPOINTS, PATHS, sendAsyncRequest } from '../../utils';
import { CONSTANTS } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';
import { HttpMethod } from '../../utils/httpMethods';
import { SHOW_SNACK_BAR } from '../snackBar/types';
import { SnackBarActionTypes } from '../snackBar/models';
export const useLogin = () => {
  const navigate = useNavigate();
  const login = (data: LoginInterface) => {
    return (dispatch: ThunkDispatch<RootState, undefined, AuthActionTypes | SnackBarActionTypes>) => {
      {
        dispatch({
          type: LOGIN_ATTEMPT,
        });
        sendAsyncRequest(HttpMethod.Post, ENDPOINTS.SIGNIN, data, false, null)
          .then((res: any) => {
            localStorage.setItem(CONSTANTS.ADMIN_DATA, JSON.stringify(res?.data.data));
            localStorage.setItem(CONSTANTS.LOCAL_STORAGE_TOKEN, res?.data.data.accessToken);
            localStorage.setItem(CONSTANTS.USER_ID, res?.data.data.id);
            localStorage.setItem(CONSTANTS.ROLE, JSON.stringify(res?.data.data.Role));
            localStorage.setItem(CONSTANTS.LOCAL_STORAGE_REFRESH_TOKEN, res?.data.data.refreshToken);
            dispatch({
              type: LOGIN_SUCCESS,
            });
            navigate(PATHS.Dashboard, { replace: true });
            // window.location.replace(PATHS.Dashboard);
          })

          .catch((error) => {
            if (error.response.data.code == CONSTANTS.INVALID_TOKEN) {
              localStorage.clear();
              dispatch({
                type: SHOW_SNACK_BAR,
                payload: {
                  message: CONSTANTS.SESSION_EXPIRED,
                  severity: 'error',
                  icon: 'ErrorICon',
                  size: 'medium',
                },
              });
              dispatch({
                type: LOGIN_ERROR,
                error: error,
              });
            } else if (error.message == 'Request failed with status code 404') {
              dispatch({
                type: SHOW_SNACK_BAR,
                payload: {
                  message: 'Le mot de passe est incorrect',
                  severity: 'error',
                  icon: 'ErrorICon',
                  size: 'large',
                },
              });
              dispatch({
                type: LOGIN_ERROR,
                error: error,
              });
            } else if (error.message == 'Request failed with status code 429') {
              dispatch({
                type: SHOW_SNACK_BAR,
                payload: {
                  message: "Trop de demandes.Merci d'essayer plus tard",
                  severity: 'error',
                  icon: 'ErrorICon',
                  size: 'large',
                },
              });
              dispatch({
                type: LOGIN_ERROR,
                error: error,
              });
            } else {
              dispatch({
                type: LOGIN_ERROR,
                error: error,
              });
            }
          });
      }
    };
  };
  return {
    login,
  };
};
export const useLogout = () => {
  const navigate = useNavigate();
  const logout = () => {
    return (dispatch: ThunkDispatch<RootState, undefined, AuthActionTypes | SnackBarActionTypes>) => {
      {
        dispatch({
          type: LOGOUT_ATTEMPT,
        });
        sendAsyncRequest(HttpMethod.Delete, ENDPOINTS.SIGNOUT, {}, true, null)
          .then((res: any) => {
            dispatch({
              type: LOGOUT_SUCCESS,
            });
            navigate(PATHS.SignIn, { replace: true });
            localStorage.removeItem(CONSTANTS.LOCAL_STORAGE_TOKEN);
            localStorage.removeItem(CONSTANTS.USER_ID);
            localStorage.removeItem(CONSTANTS.ROLE);
            localStorage.removeItem(CONSTANTS.LOCAL_STORAGE_REFRESH_TOKEN);
            localStorage.removeItem(CONSTANTS.ADMIN_DATA);
          })
          .catch((error: any) => {
            if (error.response.data.message === CONSTANTS.INVALIDE_REFRESH_TOKEN) {
              localStorage.removeItem(CONSTANTS.LOCAL_STORAGE_TOKEN);
              localStorage.removeItem(CONSTANTS.USER_ID);
              localStorage.removeItem(CONSTANTS.ROLE);
              localStorage.removeItem(CONSTANTS.LOCAL_STORAGE_REFRESH_TOKEN);
              localStorage.removeItem(CONSTANTS.ADMIN_DATA);
              dispatch({
                type: SHOW_SNACK_BAR,
                payload: {
                  message: CONSTANTS.SESSION_EXPIRED,
                  severity: 'error',
                  icon: 'ErrorICon',
                  size: 'medium',
                },
              });
              dispatch({
                type: LOGOUT_ERROR,
                error: error,
              });
            } else {
              dispatch({
                type: LOGOUT_ERROR,
                error: error,
              });
            }
          });
      }
    };
  };
  return {
    logout,
  };
};
