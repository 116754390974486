/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useEffect } from 'react';
import { Chart, Series, Size } from 'devextreme-react/chart';
import { useDispatch, useSelector } from 'react-redux';
import { getStatistic } from '../../store/dashboard/action';
import moment from 'moment';

const ChartBar = (props) => {
  const refCom = props.refCom;
  const dispatch = useDispatch();
  const statistic = useSelector((state) => state?.dashboard.dashboard);
  const datesArray = ['Dim.', 'Lun.', 'Mar.', 'Mer.', 'Jeu.', 'Ven.', 'Sam.'];
  useEffect(() => {
    dispatch(getStatistic());
  }, []);

  return (
    <>
      <div ref={refCom}>
        <Chart
          dataSource={
            statistic.soldArtLastWeek &&
            statistic.soldArtLastWeek.map((data, index) => ({
              ...data,
              date: datesArray[moment(data.date).day()],
            }))
          }
        >
          <Size height={300} width={640} />
          <Series valueField="count" argumentField="date" name="Ventes hebdomadaire" type="bar" color="#ffcc33" />
        </Chart>
      </div>
    </>
  );
};

export default ChartBar;
