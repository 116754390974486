/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import { Box, Card, CardContent, CardHeader, CircularProgress, Divider, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EmptyContent from '../../../components/EmptyContent';
import ProfileIcon from '../../../components/ProfileIcon';
import { UserProfile, UserProfilesProps } from './user-profiles.types';

export default function UserProfiles({ profiles, mainProfile, isFetchingProfiles }: UserProfilesProps) {
  const { t } = useTranslation();

  const hasProfiles = !isFetchingProfiles && profiles && profiles.length > 0;

  const getProfileName = (profile: UserProfile): string => {
    switch (profile.Role.name) {
      case 'gallery':
        return profile.galleryName;
      case 'institution':
        return profile.nickname;
      default:
        return `${profile.name} ${profile.surname}`;
    }
  };

  return (
    <Card sx={{ minHeight: '62vh' }}>
      {mainProfile && (
        <CardHeader title={`Profils de ${mainProfile?.name} ${mainProfile?.surname} (@${mainProfile?.nickname})`} sx={{ height: '65px' }} />
      )}
      <Divider />
      <CardContent>
        {hasProfiles && (
          <List component="nav" aria-label="secondary mailbox folder">
            {profiles.map((profile) => {
              return (
                <ListItemButton key={profile.id}>
                  <ListItemIcon>
                    <ProfileIcon name={profile.Role.name} />
                  </ListItemIcon>
                  <ListItemText primary={getProfileName(profile)} secondary={profile.Role.name === 'university' ? profile.profileType : ''} />
                </ListItemButton>
              );
            })}
          </List>
        )}
        {!isFetchingProfiles && !hasProfiles && (
          <EmptyContent
            title={t('profile.no_profiles_title')}
            description={t('profile.no_profiles_description')}
            sx={{
              '& span.MuiBox-root': { height: 160 },
            }}
          />
        )}
        {isFetchingProfiles && (
          <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} padding={1} paddingY={'15%'}>
            <CircularProgress
              sx={{
                color: '#ffcc33',
              }}
            />
          </Box>
        )}
      </CardContent>
    </Card>
  );
}
