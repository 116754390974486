/* eslint-disable @typescript-eslint/no-explicit-any*/

import React, { useEffect } from 'react';
import { ButtonBase, Container, Grid, InputBase, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { theme } from '../../theme/theme';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/rootStore';
import { Spinner } from '../../components';
import { showPasswordDialog } from '../../store/Dialog/actions';
import { ThunkDispatch } from 'redux-thunk';
import { AuthActionTypes } from '../../store/authentication/models';
import { PasswordDialogActionTypes } from '../../store/Dialog/models';
import { userActionTypes } from '../../store/user/models';
import { getProfile, updateProfile } from '../../store/user/action';
import { useFormik } from 'formik';
import ModifierPasswordDialog from '../../components/modifierPasswordDialog';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import fr from 'date-fns/locale/fr';
import UserRequest from '../../models/UserRequest';
import DateIcon from '../../components/svgComponents/DateIcon';
import * as yup from 'yup';
import { Stack } from '@mui/material';
import ProfilPopUp from '../../components/updateProfilPopUp/ProfilPopUp';
import { useCallbackPrompt } from '../../hooks/useCallbackPrompt';
import './profil.css';
import CheckIcon from '../../components/svgComponents/CheckIcon';
import { useTranslation } from 'react-i18next';

function Profil() {
  const schema = yup.object().shape({
    surname: yup
      .string()
      .matches(/^[^-\s][a-zA-Z_\s-]+$/, 'Le nom de famille est invalide')
      .max(20)
      .required('Le nom de famille est requis'),
    name: yup
      .string()
      .matches(/^[^-\s][a-zA-Z_\s-]+$/, 'Le nom est invalide')
      .max(20)
      .required('Le nom est requis'),
    mail: yup.string().email("Le d'adresse e-mail n'est pas valide").required("Le de l'adresse e-mail est obligatoire"),
    zip: yup
      .string()
      .matches(/^[a-zA-Z0-9]+$/, 'La ville est invalide')
      .required('Le code postal est requis')
      .min(5, 'Le code postal doit contenir au moins 5 caractéres'),
    city: yup
      .string()
      .matches(/^[a-zA-Z]+$/, 'La ville est invalide')
      .max(20)
      .required('La ville est requise'),
    address: yup.string().required("L'adresse est requise"),
    countryId: yup.string().required('Le pays est requis'),
    birthdate: yup.date().max(new Date(), 'La date de naissance ne peut pas être dans le futur').required('La date de naissance est requise'),
  });

  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, AuthActionTypes | PasswordDialogActionTypes | userActionTypes>>();
  const logoutState = useSelector((state: RootState) => state.auth.logout);
  const { loader } = logoutState;
  const userGetState = useSelector((state: RootState) => state.user.Profile);
  const { loader: getProfileLoader, user: userGet } = userGetState;
  const countriesState = useSelector((state: RootState) => state.countries);
  const { countries } = countriesState;
  const [openProfilPopUp, setOpenProfilPopUp] = React.useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(openProfilPopUp);
  const userUpdateState = useSelector((state: RootState) => state.user.NewProfile);
  const { loader: updateProfileLoader } = userUpdateState;
  const { t } = useTranslation();

  const handleClick = () => {
    dispatch(showPasswordDialog());
  };
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch(getProfile());
    }
    return () => {
      mounted = false;
    };
  }, []);

  const onUpdateProfile = (data: UserRequest) => {
    dispatch(updateProfile(data));
  };
  const formik = useFormik({
    validationSchema: schema,
    enableReinitialize: true,
    initialValues: {
      surname: userGet?.surname || '',
      name: userGet?.name || '',
      mail: userGet?.mail || '',
      zip: userGet?.zip || '',
      city: userGet?.city || '',
      birthdate: userGet?.birthdate || '',
      address: userGet?.address || '',
      countryId: userGet?.country.id || '',
    },
    onSubmit: (values) => {
      if (values !== formik.initialValues) {
        onUpdateProfile(values);
        setOpenProfilPopUp(false);
      }
    },
  });
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 200,
        borderRadius: '5px',
      },
    },
  };
  const onHandleChange = (e: React.ChangeEvent<any>) => {
    formik.handleChange(e);
    setOpenProfilPopUp(true);
  };

  if (getProfileLoader)
    return (
      <Container sx={{ minHeight: '100vh', backgroundColor: '#f5f5f5' }}>
        <Spinner loading={getProfileLoader} />
      </Container>
    );
  else
    return (
      <>
        <ModifierPasswordDialog />
        <ProfilPopUp
          // @ts-ignore
          openProfilPopUp={showPrompt}
          setOpenProfilPopUp={setOpenProfilPopUp}
          onUpdateProfile={onUpdateProfile}
          confirmNavigation={confirmNavigation}
          cancelNavigation={cancelNavigation}
          data={formik.values}
          formik={formik}
        />

        <Grid
          className="artgapi-profile"
          container
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            backgroundColor: theme.palette.secondary.main,
            minWidth: '800px',
            mx: '0',
            px: '24px',
            height: 'auto',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'grid',
              gridAutoFlow: 'column',
              alignItems: 'center',
              paddingY: '20px',
            }}
          >
            <Spinner loading={updateProfileLoader} />
            <Spinner loading={loader} />
            <Typography
              sx={{
                height: '36px',
                fontFamily: 'SFPro-Regular',
                fontSize: '30px',
                fontWeight: '300px',
                color: theme.palette.primary.light,
                justifySelf: 'start',
              }}
            >
              Mon profil
            </Typography>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'grid',
              gridAutoFlow: 'column',
              paddingY: '20px',
            }}
          >
            <form onSubmit={formik.handleSubmit} id="updateForm">
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  paddingY: '20px',
                }}
              >
                <Typography
                  sx={{
                    height: '29px',
                    flexGrow: 1,
                    fontFamily: 'SFPro-Regular',
                    fontSize: '24px',
                    color: theme.palette.primary.light,
                    justifySelf: 'start',
                    marginBottom: '20px',
                  }}
                >
                  Informations personnelles
                </Typography>
                <Grid
                  container
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    columnGap: '24px',
                    width: '100%',
                    justifySelf: 'end',
                  }}
                >
                  <Grid item sx={{ width: '426px', alignSelf: 'end', minHeight: '120px' }}>
                    <InputLabel htmlFor="name">{t('systemuser.form_fields.name.label')}</InputLabel>
                    <InputBase
                      id="name"
                      inputProps={{
                        sx: {
                          '&::placeholder': {
                            opacity: 1,
                            color: theme.palette.primary.main,
                          },
                        },
                      }}
                      sx={{ width: '100%' }}
                      value={formik.values.name}
                      onChange={(e: React.ChangeEvent<any>) => onHandleChange(e)}
                      onBlur={formik.handleBlur}
                      error={!!formik.errors.name && formik.touched.name}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <Typography
                        variant="body2"
                        sx={{
                          color: '#ff453a',
                          mt: '7px',
                          width: '170px',
                          fontFamily: 'SFPro-Regular',
                          fontSize: '12px',
                          textAlign: 'left',
                        }}
                        data-testid="name"
                      >
                        {formik.errors.name}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item sx={{ width: '426px', alignSelf: 'end', minHeight: '120px' }}>
                    <InputLabel htmlFor="surname">{t('systemuser.form_fields.surname.label')}</InputLabel>
                    <InputBase
                      id="surname"
                      inputProps={{
                        sx: {
                          '&::placeholder': {
                            opacity: 1,
                            color: theme.palette.primary.main,
                          },
                        },
                      }}
                      sx={{
                        width: '100%',
                      }}
                      value={formik.values.surname}
                      onChange={(e: React.ChangeEvent<any>) => onHandleChange(e)}
                      onBlur={formik.handleBlur}
                      error={!!formik.errors.surname && formik.touched.surname}
                    />
                    {formik.touched.surname && formik.errors.surname && (
                      <Typography
                        variant="body2"
                        sx={{
                          color: '#ff453a',
                          mt: '7px',
                          width: '170px',
                          fontFamily: 'SFPro-Regular',
                          fontSize: '12px',
                          textAlign: 'left',
                        }}
                        data-testid="surname"
                      >
                        {formik.errors.surname}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item sx={{ width: '426px', alignSelf: 'end', minHeight: '100px' }}>
                    <InputLabel htmlFor="mail">{t('systemuser.form_fields.email_Address.label')}</InputLabel>
                    <InputBase
                      id="mail"
                      inputProps={{
                        sx: {
                          '&::placeholder': {
                            opacity: 1,
                            color: ' theme.palette.primary.main',
                          },
                        },
                      }}
                      sx={{ width: '100%' }}
                      value={formik.values.mail}
                      onChange={(e: React.ChangeEvent<any>) => onHandleChange(e)}
                      onBlur={formik.handleBlur}
                      error={!!formik.errors.mail && formik.touched.mail}
                    />
                    {formik.touched.mail && formik.errors.mail && (
                      <Typography
                        variant="body2"
                        sx={{
                          color: '#ff453a',
                          mt: '7px',
                          width: '170px',
                          fontFamily: 'SFPro-Regular',
                          fontSize: '12px',
                          textAlign: 'left',
                        }}
                        data-testid="mail"
                      >
                        {formik.errors.mail}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item sx={{ justifySelf: 'start', alignSelf: 'start' }}>
                    <InputLabel htmlFor="birthdate">{t('systemuser.form_fields.birthdate.label')}</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
                      <DatePicker
                        disableFuture
                        inputFormat="dd/MM/yyyy"
                        components={{ OpenPickerIcon: DateIcon }}
                        value={formik.values.birthdate}
                        onChange={(value) => {
                          formik.setFieldValue('birthdate', value);
                          setOpenProfilPopUp(true);
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <Stack
                            direction="row"
                            alignItems="center"
                            p={1.7}
                            sx={{
                              width: 'fit-content',
                              border: 'solid 1px #e7e7e7',
                              backgroundColor: '#ffffff',
                              input: {
                                border: 'none',
                              },
                            }}
                          >
                            <input
                              style={{ border: 'none', backgroundColor: '#FFFFFF', height: '20px', fontSize: '17px', width: '130px' }}
                              ref={inputRef}
                              {...inputProps}
                              disabled
                            />
                            {InputProps?.endAdornment}
                          </Stack>
                        )}
                      />
                    </LocalizationProvider>
                    {formik.touched.birthdate && formik.errors.birthdate && (
                      <Typography
                        variant="body2"
                        sx={{
                          color: '#ff453a',
                          mt: '7px',
                          width: '170px',
                          fontFamily: 'SFPro-Regular',
                          fontSize: '12px',
                          textAlign: 'left',
                        }}
                        data-testid="city"
                      >
                        {formik.errors.birthdate}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  height: '1px',
                  marginY: '20px',
                  backgroundColor: '#e7e7e7',
                }}
              />
              <Box
                sx={{
                  width: '100%',
                  display: 'flex ',
                  flexDirection: 'column',
                }}
              >
                <Typography
                  sx={{
                    height: '29px',
                    flexGrow: 1,
                    fontFamily: 'SFPro-Regular',
                    fontSize: '24px',
                    color: theme.palette.primary.light,
                    justifySelf: 'start',
                    marginBottom: '20px',
                  }}
                >
                  {t('systemuser.form_fields.address.placeholder')}
                </Typography>
                <Grid
                  container
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    columnGap: '24px',
                    width: '100%',
                    justifySelf: 'end',
                  }}
                >
                  <Grid item sx={{ width: '436px', alignSelf: 'end', minHeight: '120px' }}>
                    <InputLabel htmlFor="address">{t('systemuser.form_fields.address.label')}</InputLabel>
                    <InputBase
                      id="address"
                      inputProps={{
                        sx: {
                          '&::placeholder': {
                            opacity: 1,
                            color: theme.palette.primary.main,
                          },
                        },
                      }}
                      sx={{ width: '100%' }}
                      value={formik.values.address}
                      onChange={(e: React.ChangeEvent<any>) => onHandleChange(e)}
                      onBlur={formik.handleBlur}
                      error={!!formik.errors.address && formik.touched.address}
                    />
                    {formik.touched.address && formik.errors.address && (
                      <Typography
                        variant="body2"
                        sx={{
                          color: '#ff453a',
                          mt: '7px',
                          width: '170px',
                          fontFamily: 'SFPro-Regular',
                          fontSize: '12px',
                          textAlign: 'left',
                        }}
                        data-testid="address"
                      >
                        {formik.errors.address}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item sx={{ width: '436px', alignSelf: 'end', minHeight: '120px' }}>
                    <InputLabel htmlFor="city">{t('systemuser.form_fields.city.label')}</InputLabel>
                    <InputBase
                      id="city"
                      inputProps={{
                        sx: {
                          '&::placeholder': {
                            opacity: 1,
                            color: theme.palette.primary.main,
                          },
                        },
                      }}
                      sx={{ width: '100%' }}
                      value={formik.values.city}
                      onChange={(e: React.ChangeEvent<any>) => onHandleChange(e)}
                      onBlur={formik.handleBlur}
                      error={!!formik.errors.city && formik.touched.city}
                    />
                    {formik.touched.city && formik.errors.city && (
                      <Typography
                        variant="body2"
                        sx={{
                          color: '#ff453a',
                          mt: '7px',
                          width: '170px',
                          fontFamily: 'SFPro-Regular',
                          fontSize: '12px',
                          textAlign: 'left',
                        }}
                        data-testid="city"
                      >
                        {formik.errors.city}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item sx={{ width: '100px', justifySelf: 'start', alignSelf: 'start', minHeight: '110px' }}>
                    <InputLabel htmlFor="zip">{t('systemuser.form_fields.zip.label')}</InputLabel>
                    <InputBase
                      id="zip"
                      inputProps={{
                        sx: {
                          '&::placeholder': {
                            opacity: 1,
                            color: theme.palette.primary.main,
                          },
                        },
                      }}
                      sx={{ width: '100%' }}
                      value={formik.values.zip}
                      onChange={(e: React.ChangeEvent<any>) => onHandleChange(e)}
                      onBlur={formik.handleBlur}
                      error={!!formik.errors.zip && formik.touched.zip}
                    />
                    {formik.touched.zip && formik.errors.zip && (
                      <Typography
                        variant="body2"
                        sx={{
                          color: '#ff453a',
                          mt: '7px',
                          width: '260px',
                          height: '29px',
                          fontFamily: 'SFPro-Regular',
                          fontSize: '12px',
                          textAlign: 'left',
                        }}
                        data-testid="zip"
                      >
                        {formik.errors.zip}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item sx={{ width: '436px', alignSelf: 'end', minHeight: '110px' }}>
                    <InputLabel htmlFor="country">{t('systemuser.form_fields.countryId.label')}</InputLabel>

                    <Select
                      sx={{
                        width: '100%',
                        height: '48px',
                        '& .MuiSelect-select': {
                          width: '100%',
                          height: '48px',
                          alignItems: 'center',
                          justifyContent: 'center',
                        },
                      }}
                      value={formik.values.countryId}
                      onChange={(event: SelectChangeEvent) => {
                        formik.setFieldValue('countryId', event.target.value);
                        setOpenProfilPopUp(true);
                      }}
                      input={
                        <InputBase
                          style={{
                            lineHeight: '40px',
                          }}
                        />
                      }
                      MenuProps={MenuProps}
                    >
                      <MenuItem disabled>
                        <em> {userGet?.country.name}</em>
                      </MenuItem>
                      {countries?.map((country) => (
                        <MenuItem key={country.id} value={country.id}>
                          {country.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched.countryId && formik.errors.countryId && (
                      <Typography
                        variant="body2"
                        sx={{
                          color: '#ff453a',
                          mt: '7px',
                          width: '170px',
                          fontFamily: 'SFPro-Regular',
                          fontSize: '12px',
                          textAlign: 'left',
                        }}
                        data-testid="countryId"
                      >
                        {formik.errors.countryId}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Box>
          <button type="submit" form="updateForm" className={!updateProfileLoader ? 'isRealsed artgapi-save ' : 'isActive artgapi-save'}>
            {!updateProfileLoader ? 'Enregistrer' : <CheckIcon />}
          </button>
          <Box
            sx={{
              width: '100%',
              height: '1px',
              marginY: '20px',
              backgroundColor: '#e7e7e7',
            }}
          />
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              paddingY: '20px',
            }}
          >
            <Typography
              variant="caption"
              sx={{
                height: '29px',
                fontFamily: 'SFPro-Regular',
                fontSize: '24px',
                fontWeight: '300px',
                color: theme.palette.primary.light,
                justifySelf: 'start',
              }}
            >
              Mot de passe
            </Typography>
            <Grid
              container
              sx={{
                display: 'flex',
                flexDirection: 'column',
                columnGap: '24px',
                width: '100%',
                justifySelf: 'flex-start',
                marginY: '20px',
              }}
              className="change-password-btn"
            >
              <ButtonBase
                onClick={handleClick}
                sx={{
                  backgroundColor: '#ffcc33',
                  height: '52px',
                  width: '246px',
                  fontFamily: 'SFPro-Regular',
                  fontSize: '17px',
                  gap: '10px',
                  textAlign: 'center',
                  alignItems: 'center',
                  justifySelf: 'end',
                  alignContent: 'flex-end',
                }}
              >
                Changer le mot de passe
              </ButtonBase>
            </Grid>
          </Box>
        </Grid>
      </>
    );
}

export default Profil;
