export const PATHS = {
  Dashboard: '/',
  SignIn: '/signIn',
  Users: '/users',
  Companies: '/Companies',
  Articles: '/articles',
  Opportunities: '/opportunities',
  Signalement: '/signalement',
  Db: '/dataBase',
  Bugs: '/Bugs',
  Profile: '/profile',
  Universities: '/universities',
  Settings: '/settings',
  Profiles: '/profiles',
  Systemusers: '/sous-administrateur',
  forgotpassword: '/forgotpassword',
  Annonces: '/annonces',
  Adsusers: '/adsUsers',
  Admins: '/admins',
  Artistes: '/artistes',
  Galleries: '/galleries',
  Artlovers: '/artlovers',
  AdsUsers: '/adsusers',
  UserReports: '/userReports',
  RevenueReports: '/revenueReports',
  AdsReports: '/adsReports',
  Interstiles: '/interstiles',
  Sponserspost: '/sponsers',
  Pendingads: '/pendingads',
  Approvedads: '/approvedads',
  Howitworks: '/howitworks',
  AdsSubscriptionPlan: '/ads-subscription-plan',
  Interests: '/interests',
  Categories: '/categories',
  Arts: '/artsOfTheWeek',
  Artworks: '/artistOfTheWeek',
  Artusers: '/artusers',
  MasterClass: '/master-class',
  SubscriptionPlan: '/subscription-plan',
  Advertisements: '/advertisements',
  ClassroomReports: '/classroom-reports',
  Transactions: '/transactions',
};
