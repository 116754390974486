/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import { ENDPOINTS, sendAsyncRequest } from '../../../utils';
import { HttpMethod } from '../../../utils/httpMethods';
import { RootState } from '../../rootStore';
import { IFetchMasterClassSuccessAction, MasterClassActionTypes } from '../slice/MasterClass-slice.types';
import { masterClassActions, masterClassReducer } from '../slice/MasterClass-slice';

export const masterClassApi = {
  reducerPath: 'masterclass',
  reducer: masterClassReducer,
  endpoints: {
    getMasterClassUsers: (params: any) => async (dispatch: ThunkDispatch<RootState, undefined, MasterClassActionTypes>) => {
      dispatch(masterClassActions.matchPending());
      sendAsyncRequest(HttpMethod.Get, ENDPOINTS.MASTERCLASS, {}, true, { ...params })
        .then((response: AxiosResponse) => {
          const responseBody = response.data;
          dispatch(masterClassActions.matchFulFilled(responseBody, 'fetch') as IFetchMasterClassSuccessAction);
        })
        .catch((error) => {
          console.log('error', error);
          dispatch(masterClassActions.matchRejected(error));
        });
    },

    addArtistsOfWeek: (data: any) => async (dispatch: ThunkDispatch<RootState, undefined, MasterClassActionTypes>) => {
      dispatch(masterClassActions.matchPending());
      return new Promise((resolve, reject) => {
        sendAsyncRequest(HttpMethod.Post, `${ENDPOINTS.MASTERCLASS}`, data, true, {})
          .then((response: AxiosResponse) => {
            const responseBody = response.data;
            dispatch(masterClassActions.matchFulFilled(responseBody, 'create') as IFetchMasterClassSuccessAction);
            resolve(responseBody); // Resolve the promise when request succeeds
          })
          .catch((error) => {
            const responseError = error.response.data;
            console.log('error', responseError.message);
            dispatch(masterClassActions.matchRejected(responseError));
            reject(responseError); // Reject the promise when request fails
          });
      });
    },
    createMasterClass: (data: any) => async (dispatch: ThunkDispatch<RootState, undefined, MasterClassActionTypes>) => {
      dispatch(masterClassActions.matchPending());
      return new Promise((resolve, reject) => {
        sendAsyncRequest(HttpMethod.Post, ENDPOINTS.MASTERCLASS, data, true, {})
          .then((response: AxiosResponse) => {
            const responseBody = response.data;
            dispatch(masterClassActions.matchFulFilled(responseBody, 'create') as IFetchMasterClassSuccessAction);
            resolve(responseBody); // Resolve the promise when request succeeds
          })
          .catch((error) => {
            const responseError = error.response.data;
            console.log('error', responseError.message);
            dispatch(masterClassActions.matchRejected(responseError));
            reject(responseError); // Reject the promise when request fails
          });
      });
    },

    updateMasterClass: (id: number, data: any) => async (dispatch: ThunkDispatch<RootState, undefined, MasterClassActionTypes>) => {
      dispatch(masterClassActions.matchPending());
      return new Promise((resolve, reject) => {
        sendAsyncRequest(HttpMethod.Put, `${ENDPOINTS.MASTERCLASS}/${id}`, data, true, {})
          .then((response: AxiosResponse) => {
            const responseBody = response.data;
            dispatch(masterClassActions.matchFulFilled(responseBody, 'update') as IFetchMasterClassSuccessAction);
            resolve(responseBody); // Resolve the promise when request succeeds
          })
          .catch((error) => {
            const responseError = error.response.data;
            console.log('error', responseError.message);
            dispatch(masterClassActions.matchRejected(responseError));
            reject(responseError); // Reject the promise when request fails
          });
      });
    },

    deleteMasterClassUser: (id: number) => async (dispatch: ThunkDispatch<RootState, undefined, MasterClassActionTypes>) => {
      dispatch(masterClassActions.matchPending());
      return new Promise((resolve, reject) => {
        sendAsyncRequest(HttpMethod.Delete, `${ENDPOINTS.MASTERCLASS}/${id}`, {}, true, {})
          .then((response: AxiosResponse) => {
            const responseBody = response.data;
            dispatch(masterClassActions.matchFulFilled(responseBody, 'delete') as IFetchMasterClassSuccessAction);
            resolve(responseBody); // Resolve the promise when request succeeds
          })
          .catch((error) => {
            const responseError = error.response.data;
            console.log('error', responseError.message);
            dispatch(masterClassActions.matchRejected(responseError));
            reject(responseError); // Reject the promise when request fails
          });
      });
    },
  },
};
