import React, { ChangeEvent, useEffect } from 'react';
import { ButtonBase, Container, Grid, IconButton, TextField, ThemeProvider, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { PaginationActionTypes } from '../../../store/pagination/models';
import { RootState } from '../../../store/rootStore';
import { searchBarTheme } from '../../../theme/searchBarTheme';
import { theme } from '../../../theme/theme';
import SearchIcon from '../../svgComponents/searchIcon';
import { getCriteria, getPageNumber } from '../../../store/pagination/actions';
import { CONSTANTS } from '../../../utils';
import { useTranslation } from 'react-i18next';

interface OnTheTableProps {
  title: string;
  name?: string;
  openDialog?: () => void;
  ShowSearchField?: boolean;
  criteria?: string;
}

/**
 *component returning text and buttons to the top of the table
 *
 * @returns {ExoticComponent<() => JSX.Element>}
 */
export const OnTheTable = (props: OnTheTableProps) => {
  const { title, name, openDialog, ShowSearchField, criteria } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, PaginationActionTypes>>();

  useEffect(() => {
    dispatch(getCriteria(''));
    dispatch(getPageNumber(0));
  }, [ShowSearchField]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(getPageNumber(0));
    dispatch(getCriteria(e.target.value));
  };
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: theme.palette.secondary.main,
        justifyContent: 'space-between',
        height: '128px',
        alignItems: 'center',
      }}
    >
      {name == CONSTANTS.DASHBOARD ? (
        <Grid
          // eslint-disable-next-line @typescript-eslint/no-shadow
          sx={(theme) => ({
            marginLeft: '-6%',
            [theme.breakpoints.down(1286)]: {
              marginLeft: '4%',
            },
          })}
        >
          <Typography variant="caption" sx={{ fontSize: '24px', color: theme.palette.primary.light, fontFamily: 'SFProText' }}>
            {title}
          </Typography>
        </Grid>
      ) : (
        <Typography variant="caption" sx={{ fontSize: '24px', color: theme.palette.primary.light, fontFamily: 'SFProText' }}>
          {title}
        </Typography>
      )}

      {ShowSearchField && (
        <ThemeProvider theme={searchBarTheme}>
          <TextField
            id="standard-bare"
            variant="outlined"
            placeholder="Rechercher"
            value={criteria}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleSearch(e)}
            InputProps={{
              endAdornment: (
                <IconButton sx={{ position: 'absolute', left: 0 }}>
                  <SearchIcon />
                </IconButton>
              ),
            }}
          />
        </ThemeProvider>
      )}
      {(title == CONSTANTS.Articles || title == CONSTANTS.Opportunities) && (
        <ButtonBase
          onClick={openDialog}
          sx={{
            height: '52px',
            width: 'auto',
            fontFamily: 'SFPro-Regular',
            fontSize: '17px',
            textAlign: 'center',
            backgroundColor: '#ffcc33',
            justifySelf: 'end',
            paddingLeft: '15px',
            paddingRight: '15px',
          }}
        >
          {title == CONSTANTS.Articles ? t('articles.create_Article') : t('opportunities.create_Opportunity')}
        </ButtonBase>
      )}
    </Container>
  );
};
