import React from 'react';
import { ButtonBase, Container, Typography } from '@mui/material';
import { theme } from '../../../theme/theme';
import CreerCampagneDialog from '../creerCampagneDialog/CreerCampagneDialog';

interface OnTheTableProps {
  title: string;
}

/**
 *component returning text and buttons to the top of the table
 *
 * @returns {ExoticComponent<() => JSX.Element>}
 */
export const CompagneFirstSection = (props: OnTheTableProps) => {
  const { title } = props;
  const [openCreerCampagneDialog, setOpenCreerCampagneDialog] = React.useState(false);
  const handleClick = () => {
    setOpenCreerCampagneDialog(!openCreerCampagneDialog);
  };
  return (
    <>
      <CreerCampagneDialog openCreerCampagneDialog={openCreerCampagneDialog} setOpenCreerCampagneDialog={setOpenCreerCampagneDialog} />
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: theme.palette.secondary.main,
          justifyContent: 'space-between',
          height: '128px',
          alignItems: 'center',
        }}
      >
        <Typography variant="caption" sx={{ fontSize: '24px', color: theme.palette.primary.light, fontFamily: 'SFPro-Regular' }}>
          {title}
        </Typography>
        <ButtonBase
          onClick={handleClick}
          sx={{
            height: '52px',
            width: '287px',
            fontFamily: 'SFPro-Regular',
            fontSize: '17px',
            textAlign: 'center',
            backgroundColor: '#ffcc33',
            justifySelf: 'end',
          }}
        >
          Créer une nouvelle compagne
        </ButtonBase>
      </Container>
    </>
  );
};
