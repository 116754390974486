import { InitialPaginationParams } from '../../../api/common/common.types';
import { Column } from '../../table/table-head/CustomTableHead.types';

export const initialPaginationState: InitialPaginationParams = {
  defaultCurrentPage: 1,
  defaultOrderBy: 'id',
  defaultOrder: 'asc',
  defaultRowsPerPage: 25,
};

export const TABLE_COLUMNS_CONFIG: Column[] = [
  {
    id: 'name',
    label: 'adds.name',
    width: '20%',
  },
  {
    id: 'mail',
    label: 'adds.email',
    width: '20%',
  },
  {
    id: 'price',
    label: 'adds.price',
    width: '20%',
  },
  {
    id: 'idRole',
    label: 'adds.role',
    width: '20%',
  },
];
