import React from 'react';

export const ErrorIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.7574 10.7574L19.2426 19.2427" stroke="#FFFFFf" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.7574 19.2427L19.2426 10.7574" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ErrorIcon;
