/* eslint-disable react/react-in-jsx-scope */
import { AddsMenu } from '../components/adds/menubar/addsMenu';
import AdvertisementListing from '../components/advertisements/AdvertisementsListing';
import Categories from '../components/interests/categories/Categories';
import Interests from '../components/interests/interests/Interests';
import MasterClassListing from '../components/masterClass/MasterClassListing';
import { CompaniesMenu } from '../components/menuBar/companiesMenu';
import { UsersMenu } from '../components/menuBar/usersMenu';
import { SignamlmentMenu } from '../components/signalement/SignamlmentMenu';
import SubscriptionPlanListing from '../components/subscriptionPlan/SubscriptionPlanListing';
import TransactionListing from '../components/transactions/TransactionListing';
import { Dashboard } from '../pages';
import Adds from '../pages/Adds/Adds';
import Profil from '../pages/Profil/Profil';
import Articles from '../pages/articles/Articles';
import BugsPage from '../pages/bugs/Bugs';
import OpportunitiesPage from '../pages/opportunities/Opportunities';
import Profiles from '../pages/profile/Profiles';
import Settings from '../pages/settings/Settings';
import SystemUsers from '../pages/systemusers/SystemUser';
import UniversityList from '../pages/university/UniversityList';
import { PATHS } from './Paths';

export interface RouteType {
  path: string;
  element?: JSX.Element;
}
export const routes: RouteType[] = [
  {
    path: PATHS.Dashboard,
    element: <Dashboard />,
  },
  {
    path: PATHS.Opportunities,
    element: <OpportunitiesPage />,
  },
  {
    path: PATHS.MasterClass,
    element: <MasterClassListing />,
  },
  // {
  //   path: PATHS.SubscriptionPlan,
  //   element: <SubscriptionPlanListing />,
  // },
  // {
  //   path: PATHS.Advertisements,
  //   element: <AdvertisementListing />,
  // },
  {
    path: PATHS.Articles,
    element: <Articles />,
  },
  {
    path: PATHS.Bugs,
    element: <BugsPage />,
  },
  {
    path: PATHS.Profile,
    element: <Profil />,
  },
  {
    path: PATHS.Systemusers,
    element: <SystemUsers />,
  },
  {
    path: PATHS.Universities,
    element: <UniversityList />,
  },
  {
    path: PATHS.Settings,
    element: <Settings />,
  },
  {
    path: PATHS.Profiles,
    element: <Profiles />,
  },
  {
    path: PATHS.Admins,
    element: <UsersMenu indexVal={0} role="admin" />,
  },
  // {
  //   path: PATHS.Artistes,
  //   element: <UsersMenu indexVal={1} role="artist" />,
  // },
  // {
  //   path: PATHS.Galleries,
  //   element: <UsersMenu indexVal={2} role="gallery" />,
  // },
  // {
  //   path: PATHS.Artlovers,
  //   element: <UsersMenu indexVal={3} role="user" />,
  // },
  {
    path: PATHS.Artusers,
    element: <UsersMenu indexVal={1} role="artist" />,
  },
  {
    path: PATHS.Arts,
    element: <UsersMenu indexVal={2} role="" />,
  },
  {
    path: PATHS.Artworks,
    element: <UsersMenu indexVal={3} role="" />,
  },
  {
    path: PATHS.AdsUsers,
    element: <UsersMenu indexVal={4} role="" />,
  },
  // signalments
  {
    path: PATHS.UserReports,
    element: <SignamlmentMenu indexVal={0} />,
  },
  {
    path: PATHS.RevenueReports,
    element: <SignamlmentMenu indexVal={1} />,
  },
  {
    path: PATHS.AdsReports,
    element: <SignamlmentMenu indexVal={2} />,
  },
  {
    path: PATHS.ClassroomReports,
    element: <SignamlmentMenu indexVal={3} />,
  },
  // transactions

  // {
  //   path: PATHS.Transactions,
  //   element: <TransactionListing />,
  // },
  // campagnes
  {
    path: PATHS.Interstiles,
    element: <CompaniesMenu indexVal={0} />,
  },
  {
    path: PATHS.Sponserspost,
    element: <CompaniesMenu indexVal={1} />,
  },
  //  addsss
  {
    path: PATHS.Pendingads,
    element: <AddsMenu indexVal={0} />,
  },
  {
    path: PATHS.Approvedads,
    element: <AddsMenu indexVal={1} />,
  },
  {
    path: PATHS.AdsSubscriptionPlan,
    element: <AddsMenu indexVal={2} />,
  },
  {
    path: PATHS.Howitworks,
    element: <AddsMenu indexVal={3} />,
  },
  {
    path: PATHS.Interests,
    element: <Interests />,
  },
  {
    path: PATHS.Categories,
    element: <Categories />,
  },
];
