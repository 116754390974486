import React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import DateIcon from '../../src/components/svgComponents/DateIcon';
import { fr } from 'date-fns/locale';
import { Button, Grid, InputLabel, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface DateFilterProps {
  startDate: Date | null;
  endDate: Date | null;
  handleClear: () => void;
  handleStartDate: (date: Date | null) => void;
  handleEndDate: (date: Date | null) => void;
}

const DateFilter: React.FC<DateFilterProps> = ({ startDate, endDate, handleClear, handleStartDate, handleEndDate }) => {
  const { t } = useTranslation();
  return (
    <>
      <Grid container xs={7} sx={{ ml: 4, mb: '10px' }} display="flex" alignItems="center">
        <Grid item>
          <InputLabel htmlFor="startDate">Date de début</InputLabel>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
            <DatePicker
              inputFormat="dd/MM/yyyy"
              components={{ OpenPickerIcon: DateIcon }}
              value={startDate}
              onChange={handleStartDate}
              maxDate={endDate || undefined} // Convert null to undefined
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  p={2}
                  sx={{
                    width: 'fit-content',
                    border: 'solid 1px #e7e7e7',
                    backgroundColor: '#ffffff',
                    input: {
                      border: 'none',
                    },
                  }}
                >
                  <input
                    style={{
                      border: 'none',
                      caretColor: 'transparent',
                      cursor: 'default',
                      outline: 'none',
                      backgroundColor: '#FFFFFF',
                      height: '20px',
                      fontSize: '17px',
                      width: '140px',
                    }}
                    ref={inputRef}
                    onKeyDown={(e) => e.preventDefault()}
                    {...inputProps}
                  />
                  {InputProps?.endAdornment}
                </Stack>
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item sx={{ ml: 2 }}>
          <InputLabel htmlFor="endDate">Date de fin</InputLabel>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
            <DatePicker
              inputFormat="dd/MM/yyyy"
              components={{ OpenPickerIcon: DateIcon }}
              value={endDate}
              onChange={handleEndDate}
              // maxDate={new Date()}
              minDate={startDate || undefined}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  p={2}
                  sx={{
                    width: 'fit-content',
                    border: 'solid 1px #e7e7e7',
                    backgroundColor: '#ffffff',
                    input: {
                      border: 'none',
                    },
                  }}
                >
                  <input
                    style={{
                      border: 'none',
                      cursor: 'default',
                      caretColor: 'transparent',
                      outline: 'none',
                      backgroundColor: '#FFFFFF',
                      height: '20px',
                      fontSize: '17px',
                      width: '140px',
                    }}
                    ref={inputRef}
                    onKeyDown={(e) => e.preventDefault()}
                    {...inputProps}
                  />
                  {InputProps?.endAdornment}
                </Stack>
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item>
          <InputLabel htmlFor="nolabel"></InputLabel>
          <Button
            sx={{ ml: 2 }}
            variant="contained"
            style={{
              cursor: 'pointer',
              backgroundColor: '#1976d2',
              color: '#fff',
            }}
            onClick={handleClear}
          >
            {t('common.clear')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default DateFilter;
