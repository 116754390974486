/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import { Button, Grid, InputLabel, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { getAllFlags } from '../../store/flags/action';
import { flagsActionsTypes } from '../../store/flags/models';
import { getPageNumber, getTypeFlag } from '../../store/pagination/actions';
import { PaginationActionTypes } from '../../store/pagination/models';
import { RootState } from '../../store/rootStore';
import { CONSTANTS } from '../../utils';
import { OnTheTable } from '../table/onTheTable/OnTheTable';
import { TableFactory } from '../table/tableFactory/TableFactory';
import { flagsHeader } from './flagsHeader';
import { useTranslation } from 'react-i18next';
import DateFilter from '../../utils/DateFilter';
/**
 * Component usersList
 *
 * @component
 *
 * @example
 * return (
 *   <usersList/>
 * )
 */
const FlagsList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, flagsActionsTypes | PaginationActionTypes>>();
  const flagsState = useSelector((state: RootState) => state.flags.flages);
  const loader = useSelector((state: RootState) => state.flags.loading);
  const loadingDetailFlag = useSelector((state: RootState) => state.flags.loadingDetailFlag);

  const paginationState = useSelector((state: RootState) => state.pagination);
  const { pageNumber, perPage, statusFlag, typeFlag } = paginationState;
  const [status, setStatus] = useState(statusFlag);
  const [type, setType] = useState(typeFlag);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  function formatDate(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const formattedStartDate = startDate ? formatDate(startDate) : '';
  const formattedEndDate = endDate ? formatDate(endDate) : '';

  useEffect(() => {
    setStatus(statusFlag);
  }, [statusFlag]);

  useEffect(() => {
    dispatch(getTypeFlag(type ?? ''));
  }, [type]);
  useEffect(() => {
    setType(typeFlag);
  }, [typeFlag]);

  const selectStatus = (value: string) => {
    if (value === 'Traité') {
      setStatus('treated');
    } else if (value === 'Signalé') {
      setStatus('reported');
    } else if (value === 'Utilisateur') {
      setType('user');
    } else if (value === 'Publication') {
      setType('publication');
    } else if (value === 'Commentaire') {
      setType('comment');
    } else if (value === 'Article') {
      setType('articleComment');
    } else {
      setStatus('');
      setType('');
    }
    dispatch(getPageNumber(0));
  };

  useEffect(() => {
    if (!loader) dispatch(getAllFlags(pageNumber + 1, perPage, status, type, formattedStartDate, formattedEndDate));
  }, [perPage, pageNumber, status, type, startDate, endDate]);

  const switchFlagType = (flag: any) => {
    if (flag.flaggedPublication) return 'Publication';
    if (flag.flaggedUser) return 'Artiste';
    if (flag.flaggedPublicationComment || flag.flaggedPublicationCommentResponse) return 'Commentaire';
    if (flag.flaggedArticleComment) return 'Article';
  };

  const handleClear = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const handleStartDate = (date: Date | null) => {
    setStartDate(date);
  };

  const handleEndDate = (date: Date | null) => {
    setEndDate(date);
  };

  return (
    <>
      <Grid container direction="column" rowSpacing={5}>
        <Grid item>
          <OnTheTable title={t('common.user_reports')} />
          <DateFilter
            startDate={startDate}
            endDate={endDate}
            handleClear={handleClear}
            handleStartDate={handleStartDate}
            handleEndDate={handleEndDate}
          />
          <TableFactory
            headItems={flagsHeader}
            bodyData={
              flagsState &&
              flagsState.map((flag) => ({
                ...flag,
                type: switchFlagType(flag),
              }))
            }
            name={CONSTANTS.Flags}
            handleFilter={selectStatus}
            loading={loader}
            loadingDetailFlag={loadingDetailFlag}
            statusTable={statusFlag}
            type={type}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FlagsList;
