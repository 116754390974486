import { CONSTANTS } from '../../utils';

export interface Description {
  label: string;
  type: string;
  height: number;
  width: string;
  choices?: { label: string; value: string; id?: number }[];
  choicesTypes?: { label: string; id?: number }[];
  value: string;
  filterBy?: string;
}

export const flagsHeader: Description[] = [
  {
    label: CONSTANTS.RAISON,
    type: 'normal',
    height: 50,
    width: '30%',
    value: 'flag',
  },
  {
    label: CONSTANTS.TYPE,
    type: 'select',
    height: 50,
    width: '15%',
    value: 'type',
    choicesTypes: [
      { label: 'Tous', id: 10 },
      { label: 'Commentaire', id: 20 },
      { label: 'Publication', id: 30 },
      { label: 'Utilisateur', id: 40 },
      { label: 'Article', id: 50 },
    ],
  },
  {
    label: CONSTANTS.USER,
    type: 'normal',
    height: 50,
    width: '20%',
    value: 'nickname',
  },
  {
    label: CONSTANTS.STATUS,
    type: 'select',
    height: 50,
    width: '15%',
    value: 'status',
    choices: [
      { label: 'Tous', value: '10', id: 10 },
      { label: 'Signalé', value: '50', id: 50 },
      { label: 'Traité', value: '40', id: 40 },
    ],
  },
  {
    label: CONSTANTS.DATE,
    type: 'dateFlags',
    height: 50,
    width: '35%',
    value: 'createdAt',
    filterBy: 'dateFlags',
  },
];
