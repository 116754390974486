import HeaderProperty from '../../models/Table';
import { CONSTANTS } from '../../utils';

export const ArticlesHeader: HeaderProperty[] = [
  {
    label: CONSTANTS.PHOTO,
    type: 'image',
    height: 50,
    width: '10%',
    value: 'minImage',
    filterBy: 'image',
  },
  {
    label: CONSTANTS.TITRE,
    type: 'normal',
    height: 50,
    width: '30%',
    value: 'title',
  },
  {
    label: CONSTANTS.CATEGORY,
    type: 'select',
    height: 50,
    width: '20%',
    value: 'category',
    filterBy: 'articleCategory',
    choicesTypes: [
      { label: 'articles.all', id: 10 },
      { label: 'articles.category_article', id: 20 },
      { label: 'articles.category_podcast', id: 30 },
    ],
  },
  {
    label: CONSTANTS.STATUS,
    type: 'select',
    height: 50,
    width: '20%',
    value: 'status',
    filterBy: 'articleStatus',
    choices: [
      { label: 'articles.all', id: 10 },
      { label: 'articles.draft_status', id: 20 },
      { label: 'articles.published_status', id: 30 },
      { label: 'articles.deleted_status', id: 40 },
    ],
  },
  {
    label: CONSTANTS.DATE_PUB,
    type: 'date',
    height: 50,
    width: '25%',
    value: 'createdAt',
    filterBy: 'date',
  },
];
