import React from 'react';
import { Button, TableRow, Stack, IconButton, Tooltip } from '@mui/material';
import { TableCellStyle } from './MasterClassRow.styles';
import { MasterClassRowProps } from './MasterClassRow.types';
import EyeIcon from '../../svgComponents/EyeIcon';
import Iconify from '../../Iconify';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/ic_trash.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/pencil-outline.svg';
import { useTranslation } from 'react-i18next';

export default function MasterClassTableRow({ row, selected, onShowDetails, columnsConfig, onOPenEditDialog, onConfirmDelete }: MasterClassRowProps) {
  const { id, title, isPaid } = row;
  const { t } = useTranslation();

  return (
    <TableRow key={id} hover selected={selected} sx={{ backgroundColor: '#ffffff' }}>
      {columnsConfig.map((column) => (
        <TableCellStyle
          key={column.id}
          align={column.align || 'left'}
          width={column.width}
          className={column.id === 'description' ? 'hide-text-overflow' : ''}
        >
          {column.id === 'isPaid' ? (
            isPaid === 1 ? (
              <Button variant="contained" size="small" style={{ color: '#fff', backgroundColor: '#4caf50' }} disabled>
                {t('masterClass.paid')}
              </Button>
            ) : (
              <Button variant="contained" size="small" style={{ color: '#fff', backgroundColor: '#1976D2' }} disabled>
                {t('masterClass.free')}
              </Button>
            )
          ) : column.id !== 'actions' ? (
            String(row[column.id as keyof typeof row])
          ) : (
            <Stack direction="row" spacing={2}>
              <Tooltip title="View Details" arrow>
                <IconButton sx={{ padding: '0px !important' }} onClick={() => onShowDetails(id, true)}>
                  <EyeIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit" arrow>
                <IconButton onClick={() => onOPenEditDialog(id)}>
                  <Iconify icon={EditIcon} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete" arrow>
                <IconButton onClick={() => onConfirmDelete(id)}>
                  <Iconify icon={DeleteIcon} />
                </IconButton>
              </Tooltip>
            </Stack>
          )}
        </TableCellStyle>
      ))}
    </TableRow>
  );
}
