/* eslint-disable @typescript-eslint/no-unused-expressions */
import React from 'react';
import { Button, Container, Grid, Typography } from '@mui/material';
import { changestatusBug } from '../../../store/bugs/action';
import { useDispatch, useSelector } from 'react-redux';
import { Bug, BugsActionTypes } from '../../../store/bugs/models';
import { CONSTANTS } from '../../../utils';
import Spinner from '../../spinner';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../../store/rootStore';

interface BugDialogHeader {
  bug: Bug;
  title: string;
  loadingState?: boolean;
  statusTable?: string;
  onClose: () => void;
}
/* ***** BUG DIALOG HEADER ****** */
export const BugDialogHeader = (props: BugDialogHeader) => {
  const { loadingState, statusTable, bug, title, onClose } = props;
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, BugsActionTypes>>();
  const paginationState = useSelector((state: RootState) => state.pagination);
  const { pageNumber, perPage } = paginationState;

  const changeStatusBug = () => {
    if (bug?.data && bug.data.status === 'closed') {
      bug.data.id && dispatch(changestatusBug(bug.data.id, 'open', statusTable, pageNumber + 1, perPage));
    }
    if (bug?.data && bug.data.status === 'open') {
      bug?.data.id && dispatch(changestatusBug(bug.data.id, 'closed', statusTable, pageNumber + 1, perPage));
    }
    onClose();
  };

  return (
    <>
      {loadingState ? (
        <Container sx={{ minHeight: '100vh', backgroundColor: '#f5f5f5' }}>
          <Spinner loading={loadingState} />
        </Container>
      ) : (
        <>
          <Grid item xs={9}>
            <Typography variant="h6" sx={{ ml: 4, mt: 2 }}>
              {title}
            </Typography>
          </Grid>
          <Grid item xs={3} container display="row" sx={{ pl: 3 }}>
            <Button style={{ color: '#a4a4a5', width: '125px', height: '84px' }} onClick={onClose}>
              <Typography style={{ fontSize: '17px' }}>{CONSTANTS.ANNULER}</Typography>
            </Button>
            <Button
              style={{ backgroundColor: '#ffcc33', color: 'black', width: '125px', height: '84px', borderRadius: 0 }}
              onClick={() => {
                changeStatusBug();
              }}
            >
              <Typography style={{ fontSize: '17px' }}> {bug?.data && bug.data.status === 'closed' ? 'Ouvrir' : 'Fermer'}</Typography>
            </Button>
          </Grid>
        </>
      )}
    </>
  );
};

export default BugDialogHeader;
